import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const URL = cs.BaseURL + '/op-allocation/detail';
const updateUserURL = cs.BaseURL + '/op-allocation/update';
const styles = theme => ({});

class EditOPAllocation extends React.Component {
    state = {
        id: this.props.match.params.id,
        user_name: '',
        mc_product_name: '',
        allocation_weight: -1,
        is_enabled: false
    };
    constructor(props) {
        super(props);

        this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?new=true&id=${id}`;
        return queryString;
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState(
                        {
                            user_name: data.data.user_name,
                            mc_product_name: data.data.mc_product_name,
                            allocation_weight: data.data.allocation_weight,
                            is_enabled: parseInt(data.data.is_enabled) 
                        },
                        () => {}
                    );
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        if (this.state.allocation_weight < 0) {
            window.alert('Vui lòng nhập đúng Trọng Số !');
            return;
        }

        let queryString = `${updateUserURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: this.state.id,
                allocation_weight: this.state.allocation_weight,
                is_enabled: this.state.is_enabled ? 1 : 0
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Đã cập nhật !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/op_allocation',
                    state: { reload: true }
                });
            })
            .catch(() => {});
    };

    handleChange = name => event => {
        if (name == 'is_enabled') this.setState({ is_enabled: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {}
            );
    };

    render() {
        const { classes } = this.props;
        const { op_allocation } = this.state;
        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title">
                    <DialogTitle id="edit_op_allocation_form-dialog-title">Cập nhật thông tin phân bổ</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                disabled
                                margin="dense"
                                id="op_allocation_edit_user_name"
                                required={true}
                                value={this.state.user_name}
                                label="Nhân viên"
                                type="text"
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                disabled
                                margin="dense"
                                id="op_allocation_edit_mc_product_name"
                                required={true}
                                value={this.state.mc_product_name}
                                label="Sản phẩm"
                                type="text"
                            />
                        </FormControl>

                        <TextField
                            fullWidth
                            margin="dense"
                            id="op_allocation_edit_weight"
                            required={true}
                            value={this.state.allocation_weight}
                            onChange={this.handleChange('allocation_weight')}
                            label="Tỷ trọng"
                            type="number"
                        />

                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.is_enabled}
                                value={this.state.is_enabled}
                                onChange={this.handleChange('is_enabled')}
                                color="primary"
                            />
                            {this.state.is_enabled ? 'Hiệu lực' : 'Vô hiệu'}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Cập nhật
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

EditOPAllocation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(EditOPAllocation));
