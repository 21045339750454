import React from 'react';
import Paper from '@material-ui/core/Paper';
import { compose, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

var format = require('string-format');

const URL2 = cs.BaseURL + '/enduser/detail/user';

class OrderUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderCode: this.props.orderCode,
            user: null,
            infos: [],
            enduser: this.props.endUser,
        };
    }
    componentDidMount() {

        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        //let queryString = `${URL}?order_code=${orderCode}`;
        let queryString = `${URL2}?new=true&order_code=${orderCode}`;

        return queryString;
    }

    loadData = conditions => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data && data.data) {
                    var user = data.data;
                    console.log(user);
                    var related_people = null;
                    try {
                        related_people = JSON.parse(user.related_people);
                    } catch (error) {
                        //related_people= {};
                    }

                    console.log(related_people);
                    var objs = [];
                    if (user) {
                        objs.push(
                            ...[
                                {
                                    caption: 'Username',
                                    value: user.username
                                },
                                {
                                    caption: 'Giới tính',
                                    value: user.gender ? 'Nam' : 'Nữ'
                                },
                                {
                                    caption: 'Tên đầy đủ',
                                    value: user.full_name
                                },
                                {
                                    caption: 'Ngày sinh',
                                    value: `${user.birth_day}/${user.birth_month}/${user.birth_year}`
                                },
                                {
                                    caption: 'Địa chỉ',
                                    value: user.full_address
                                },

                                {
                                    caption: 'Email',
                                    value: user.email
                                },
                                {
                                    caption: 'CMND',
                                    value: user.cmnd
                                },
                                {
                                    caption: 'CCCD',
                                    value: user.cccd
                                },
                                {
                                    caption: 'Hộ chiếu',
                                    value: user.passport
                                },
                                {
                                    caption: 'Ngày tạo CMND',
                                    value: user.cmnd_date ? moment
                                        .unix(user.cmnd_date)
                                        .tz('Asia/Ho_Chi_Minh')
                                        .format('DD/MM/YYYY HH:mm') : null
                                },
                                {
                                    caption: 'Nơi cấp CMND',
                                    value: user.cmnd_place
                                },
                                {
                                    caption: 'Ngày tạo CCCD',
                                    value: user.cccd_date ? moment
                                        .unix(user.cccd_date)
                                        .tz('Asia/Ho_Chi_Minh')
                                        .format('DD/MM/YYYY HH:mm') : null
                                },
                                {
                                    caption: 'Nơi cấp CCCD',
                                    value: user.cccd_place
                                },
                                {
                                    caption: 'Ngày tạo hộ chiếu',
                                    value: user.passport_date ? moment
                                        .unix(user.passport_date)
                                        .tz('Asia/Ho_Chi_Minh')
                                        .format('DD/MM/YYYY HH:mm') : null
                                },
                                {
                                    caption: 'Nơi cấp hộ chiếu',
                                    value: user.passport_place
                                },

                                {
                                    caption: 'Địa chỉ thường trú-Tỉnh/Thành phố',
                                    value: user.province
                                },
                                {
                                    caption: 'Địa chỉ thường trú-Quận/Huyện',
                                    value: user.district
                                },
                                {
                                    caption: 'Địa chỉ thường trú-Xã/Thị trấn',
                                    value: user.commune
                                },
                                {
                                    caption: 'Địa chỉ thường trú-Đường',
                                    value: user.street
                                },

                                {
                                    caption: 'Địa chỉ hiện tại-Tỉnh/Thành phố',
                                    value: user.second_province
                                },
                                {
                                    caption: 'Địa chỉ hiện tại-Quận/Huyện',
                                    value: user.second_district
                                },
                                {
                                    caption: 'Địa chỉ hiện tại-Xã/Thị trấn',
                                    value: user.second_commune
                                },
                                {
                                    caption: 'Địa chỉ hiện tại-Đường',
                                    value: user.second_street
                                },
                                {
                                    caption: 'Người thân 1 - Mối quan hệ',
                                    value: related_people?related_people.relationship1 : ''
                                },
                                {
                                    caption: 'Người thân 1 - Họ và tên',
                                    value: related_people ? related_people.fullName1 : null
                                },
                                {
                                    caption: 'Người thân 1 - Số điện thoại',
                                    value: related_people ? related_people.phone1 : null
                                },
                                {
                                    caption: 'Người thân 2 - Mối quan hệ',
                                    value: related_people ? related_people.relationship2 : null
                                },
                                {
                                    caption: 'Người thân 2 - Họ và tên',
                                    value: related_people ? related_people.fullName2 : null
                                },
                                {
                                    caption: 'Người thân 2 - Số điện thoại',
                                    value: related_people ? related_people.phone2 : null
                                },
                            ]
                        );
                    }
                    console.log(objs)
                    // if (order.reference_info) {
                    //     try {
                    //         var infos = JSON.parse(order.reference_info.toString());

                    //         if (infos && Array.isArray(infos)) {
                    //             for (var i = 0; i < infos.length; i++) {
                    //                 objs.push({
                    //                     caption:
                    //                         infos[i].caption != null &&
                    //                             infos[i].caption != undefined
                    //                             ? infos[i].caption.toString()
                    //                             : '',
                    //                     value:
                    //                         infos[i].value != null && infos[i].value != undefined
                    //                             ? infos[i].value.toString()
                    //                             : ''
                    //                 });
                    //             }
                    //         }
                    //     } catch (er) {
                    //         objs = [];
                    //     }
                    // }
                    this.setState({
                        user: data.data,
                        infos: objs,
                        loading: false
                    });
                } else {
                    this.setState({
                        user: null,
                        infos: [],
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    render() {
        const { order, infos } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <List>
                    <ListItem
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            display: 'block'
                        }}
                    >
                        {infos &&
                            infos.map(item => (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="first-start"
                                        alignItems="stretch"
                                        spacing={16}
                                    >
                                        <Grid item xs={3}>
                                            <ListItemText
                                                primary={
                                                    <Typography component="body2">
                                                        {item.caption != undefined
                                                            ? item.caption
                                                            : ''}
                                                    </Typography>
                                                }
                                                style={{ alignItems: 'left', padding: 0 }}
                                            />
                                        </Grid>

                                        <Grid item xs={9}>
                                            <ListItemText
                                                primary={
                                                    // <Typography variant="button">
                                                    <Typography component="body2">
                                                        {item.value != undefined
                                                            ? item.value
                                                            : ''}
                                                    </Typography>
                                                }
                                                style={{
                                                    alignContent: 'left',
                                                    marginTop: 5,
                                                    whiteSpace: 'pre-line'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{ margin: '10px 0 10px 0' }} />
                                </div>
                            ))}
                    </ListItem>
                </List>
            </div>
        );
    }
}

const styles = theme => ({
    inner_container: {
        padding: 20
    }
});
export default withStyles(styles)(OrderUser);
