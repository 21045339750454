import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';

const URL = cs.BaseURL + '/order/list';

function formatValue(value) {
    if (value && value.created_at)
        value.created_at_display = moment
            .unix(value.created_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM HH:mm');

    if (value && value.cus_adr_state)
        value.cus_adr_state = value.cus_adr_state.replace('Thành phố', 'TP').replace('Tỉnh', '');
    if (value && value.cus_adr_district)
        value.cus_adr_district = value.cus_adr_district.replace('Thành phố', 'TP');

    if (value && value.status) value.status = value.status_desc;

    return value;
}
const isOPUser = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_User;
};

class AllocatedOrders extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-op-user');
        if (user) username = JSON.parse(String(user)).name;

        this.state = {
            columns: [
                { name: 'created_at_display', title: 'Tạo lúc' },
                { name: 'agent_name', title: 'Nhân viên' },
                // { name: 'sub_region', title: 'Vùng' },
                { name: 'cus_adr_state', title: 'Tỉnh/TP' },
                { name: 'mc_code', title: 'Đối tác' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                // { name: 'order_code', title: 'Hồ sơ' },
                { name: 'cus_name', title: 'Khách hàng' },
                { name: 'cus_phone', title: 'Số ĐT' },
                // { name: 'cus_national_id', title: 'CMND' },
                // { name: 'cus_adr_district', title: 'Quận/Huyện' },
                { name: 'status', title: 'Trạng thái' },
                { name: 'action', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'created_at_display', align: 'center', width: 100 },
                // { columnName: 'sub_region', align: 'center', width: 60 },
                { columnName: 'cus_adr_state', align: 'center', width: 120 },
                { columnName: 'mc_code', align: 'center', width: 80 },
                { columnName: 'mc_product_name', align: 'left' , width: 190},
                // { columnName: 'order_code', align: 'center', width: 100 },
                { columnName: 'cus_name', align: 'left' },
                { columnName: 'cus_phone', align: 'center', width: 120 },
                // { columnName: 'cus_national_id', align: 'center', width: 120 },
                // { columnName: 'cus_adr_district', align: 'center', width: 200 },
                { columnName: 'agent_name', align: 'left', width: 180 },
                { columnName: 'action', align: 'center', width: 90 },
                { columnName: 'status', align: 'center', width: 120 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'created_at', direction: 'desc' }],
            searchStatus: '',

            search_created_at_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .subtract(1, 'month')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_created_at_end: moment()
                .tz('Asia/Ho_Chi_Minh')
                .endOf('day')
                .format('YYYY-MM-DD')
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        // this.changeSorting = this.changeSorting.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }
    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }
    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            searchStatus,
            searchOrderCode,
            searchMerchant,
            searchProduct,
            searchCusName,
            searchCusPhone,
            searchCusNationalID,
            searchAdrState,
            searchAdrDistrict,
            searchAgentName,
            searchMerchantRefCode,
            search_created_at_start,
            search_created_at_end
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        if (searchStatus && searchStatus != '')
            queryString = `${queryString}&status=${searchStatus}`;
        else queryString = `${queryString}&status_group=Allocated`;

        if (searchOrderCode) queryString = `${queryString}&order_code=${searchOrderCode}`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchCusName) queryString = `${queryString}&cus_name=${searchCusName}`;
        if (searchCusPhone) queryString = `${queryString}&cus_phone=${searchCusPhone}`;
        if (searchCusNationalID)
            queryString = `${queryString}&cus_national_id=${searchCusNationalID}`;
        if (searchAdrState) queryString = `${queryString}&cus_adr_state=${searchAdrState}`;
        if (searchAdrDistrict) queryString = `${queryString}&cus_adr_district=${searchAdrDistrict}`;
        if (searchAgentName) queryString = `${queryString}&agent_name=${searchAgentName}`;
        if (searchMerchantRefCode)
            queryString = `${queryString}&mc_order_ref=${searchMerchantRefCode}`;
        if (search_created_at_start)
            queryString = `${queryString}&created_at_start=${moment(search_created_at_start)
                .startOf('day')
                .unix()}`;
        if (search_created_at_end)
            queryString = `${queryString}&created_at_end=${moment(search_created_at_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadData = conditions => {
        const queryString = this.queryString();
        // if (this.props.history.location.state && !this.props.history.location.state.reload) {
        //     var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-op-new-orders'));
        //     if (lastData && queryString === lastData.query && parseInt(lastData.query_at) + 60 > moment().unix()) {
        //         this.setState({ ...lastData, loading: false });
        //         return;
        //     }
        // }
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                    // localStorage.setItem(
                    //     cs.System_Code + '-op-new-orders',
                    //     JSON.stringify({
                    //         rows: data.data,
                    //         totalCount: data.total_count,
                    //         query: queryString,
                    //         query_at: moment().unix()
                    //     })
                    // );
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    // localStorage.setItem(cs.System_Code + '-op-new-orders', null);
                    if (data && data.code === cs.erAuthenticationFailed) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                // localStorage.setItem(cs.System_Code + '-op-new-orders', null);
                this.setState({ loading: false });
            });
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.state.currentPage = 0;
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        className={classes.search_heading}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="order_code"
                                label="Hồ sơ"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchOrderCode}
                                onChange={this.handleChange('searchOrderCode')}
                                margin="normal"
                            />
                            <TextField
                                id="mc_code"
                                label="Đối tác"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchMerchant}
                                onChange={this.handleChange('searchMerchant')}
                                margin="normal"
                            />
                            <TextField
                                id="product_code"
                                label="Sản phẩm"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchProduct}
                                onChange={this.handleChange('searchProduct')}
                                margin="normal"
                            />
                            <TextField
                                id="cus_name"
                                label="Khách hàng"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusName}
                                onChange={this.handleChange('searchCusName')}
                                margin="normal"
                            />
                            <TextField
                                id="cus_phone"
                                label="Số đ.thoại"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusPhone}
                                onChange={this.handleChange('searchCusPhone')}
                                margin="normal"
                            />
                            <TextField
                                id="cus_national_id"
                                label="CMND"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusNationalID}
                                onChange={this.handleChange('searchCusNationalID')}
                                margin="normal"
                            />
                            <TextField
                                id="adr_state"
                                label="Tỉnh/thành"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchAdrState}
                                onChange={this.handleChange('searchAdrState')}
                                margin="normal"
                            />
                            <TextField
                                id="adr_district"
                                label="Quận / huyện"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchAdrDistrict}
                                onChange={this.handleChange('searchAdrDistrict')}
                                margin="normal"
                            />

                            <TextField
                                id="agent_name"
                                label="Nhân viên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchAgentName}
                                onChange={this.handleChange('searchAgentName')}
                                margin="normal"
                            />
                            <TextField
                                id="mc_order_ref"
                                label="Mã hồ sơ (đối tác)"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchMerchantRefCode}
                                onChange={this.handleChange('searchMerchantRefCode')}
                                margin="normal"
                            />
                            {/* <TextField
                                id="order_status"
                                select
                                label="Trạng thái"
                                className={classes.search_field}
                                value={this.state.searchStatus}
                                onChange={this.handleChange('searchStatus')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.search_select_menu
                                    }
                                }}
                                margin="normal"
                            >
                                <MenuItem value="">
                                    <em />
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_AgentAssigned}>
                                    Đã phân công
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_AgentAccepted}>
                                    Đang thực hiện
                                </MenuItem>
                            </TextField> */}

                            <TextField
                                id="search_created_at_start"
                                label="Tạo từ ngày"
                                className={classes.search_field}
                                value={this.state.search_created_at_start}
                                onChange={this.handleChange('search_created_at_start')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />

                            <TextField
                                id="search_created_at_end"
                                label="Đến ngày"
                                className={classes.search_field}
                                value={this.state.search_created_at_end}
                                onChange={this.handleChange('search_created_at_end')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                           
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            // showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const getRowId = row => row.id;
AllocatedOrders.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 1,
                    justifyContent: 'center',
                    fontSize: '12px'
                }}
            >
                <span>
                    <Button
                        color="primary"
                        component={Link}
                        to={{
                            pathname: '/detail/' + this.props.row.order_code,
                            state: { previous: '/new' }
                        }}
                    >
                        Chi tiết
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    var isValid = true;
    
    var status = props.tableRow.row.status;
    if (
        status < cs.OrderStatus_Submitted ||
        status == cs.OrderStatus_OpRejected ||
        status == cs.OrderStatus_OPSupplementRequest ||
        status == cs.OrderStatus_MerchantSupplementRequest ||
        status == cs.OrderStatus_MerchantRejected
    ) {
        var mc_product_ex_sla_hours = props.tableRow.row.mc_product_ex_sla_hours;
        var mc_product_in_sla_hours = props.tableRow.row.mc_product_in_sla_hours;
        var mc_product_imp_sla_hours = props.tableRow.row.mc_product_imp_sla_hours;

        if (mc_product_ex_sla_hours && parseInt(mc_product_ex_sla_hours))
            isValid =
                isValid &&
                moment().diff(props.tableRow.row.created_at, 'hours', true) <
                    parseInt(mc_product_ex_sla_hours);

        if (mc_product_in_sla_hours && parseInt(mc_product_in_sla_hours))
            isValid =
                isValid &&
                moment().diff(props.tableRow.row.created_at, 'hours', true) <
                    parseInt(mc_product_in_sla_hours);

        if (mc_product_imp_sla_hours && parseInt(mc_product_imp_sla_hours))
            isValid =
                isValid &&
                moment().diff(props.tableRow.row.agent_assigned_at, 'hours', true) <
                    parseInt(mc_product_in_sla_hours);
    }
    if (!isValid)
        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2,
                    color: '#dd4113',
                    fontSize: '12px'
                }}
            />
        );
    else
        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2,
                    fontSize: '12px'
                }}
            />
        );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => (
    <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(AllocatedOrders));
