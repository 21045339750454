import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';
import { Chart, ArgumentAxis, ValueAxis, BarSeries, Legend } from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';

const productsURL = cs.BaseURL + '/product/list';
const URL = cs.BaseURL + '/report/daily_summary';

function formatGridValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY');
    if (value.created_qty == 0 || value.created_qty == '0') value.created_qty = '-';
    if (value.canceled_qty == 0 || value.canceled_qty == '0') value.canceled_qty = '-';
    if (value.agent_submitted_qty == 0 || value.agent_submitted_qty == '0') value.agent_submitted_qty = '-';
    if (value.op_approved_qty == 0 || value.op_approved_qty == '0') value.op_approved_qty = '-';
    if (value.op_rejected_qty == 0 || value.op_rejected_qty == '0') value.op_rejected_qty = '-';
    if (value.mc_approved_qty == 0 || value.mc_approved_qty == '0') value.mc_approved_qty = '-';
    if (value.mc_rejected_qty == 0 || value.mc_rejected_qty == '0') value.mc_rejected_qty = '-';
    return value;
}

function formatChartValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM');
    return value;
}
class DailySummaryByDSA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            merchants: [],
            products: [],
            chartData: [],

            grid1Data: [],
            grid1Columns: [
                { name: 'report_date', title: 'Ngày' },
                // { name: 'region', title: 'Miền' },
                { name: 'sub_region', title: 'Vùng' },
                { name: 'agent_name', title: 'DSA' },
                // { name: 'created_qty', title: 'Tạo mới' },
                // { name: 'canceled_qty', title: 'Hủy' },
                { name: 'agent_submitted_qty', title: 'Đẩy hồ sơ' },
                { name: 'op_approved_qty', title: 'OP duyệt' },
                { name: 'op_rejected_qty', title: 'OP từ chối' },
                { name: 'mc_approved_qty', title: 'Đối tác duyệt' },
                { name: 'mc_rejected_qty', title: 'Đối tác từ chối' }
                // { name: 'other', title: '  ' }
            ],
            grid1ColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 110 },
                // { columnName: 'region', align: 'center', width: 150 },
                { columnName: 'sub_region', align: 'center', width: 110 },
                // { columnName: 'created_qty', align: 'center', width: 150 },
                // { columnName: 'canceled_qty', align: 'center', width: 150 },
                { columnName: 'agent_name', align: 'center' },
                {
                    columnName: 'agent_submitted_qty',
                    align: 'center',
                    width: 110
                },
                { columnName: 'op_approved_qty', align: 'center', width: 110 },
                { columnName: 'op_rejected_qty', align: 'center', width: 110 },
                { columnName: 'mc_approved_qty', align: 'center', width: 110 },
                { columnName: 'mc_rejected_qty', align: 'center', width: 110 }
                // { columnName: 'other', align: 'center', width: 150 }
            ],
            grid1TotalCount: 0,
            grid1CurrentPage: 0,

            grid2Data: [],
            grid2Columns: [
                { name: 'report_date', title: 'Ngày' },
                { name: 'agent_name', title: 'DSA' },
                { name: 'mc_code', title: 'Đối tác' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                // { name: 'created_qty', title: 'Tạo mới' },
                // { name: 'canceled_qty', title: 'Hủy' },
                { name: 'agent_submitted_qty', title: 'Đẩy hồ sơ' },
                { name: 'op_approved_qty', title: 'OP duyệt' },
                { name: 'op_rejected_qty', title: 'OP từ chối' },
                { name: 'mc_approved_qty', title: 'Đối tác duyệt' },
                { name: 'mc_rejected_qty', title: 'Đối tác từ chối' }
                // { name: 'other', title: '  ' }
            ],
            grid2ColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 110 },
                { columnName: 'agent_name', align: 'center', width: 220 },
                { columnName: 'mc_code', align: 'center', width: 130 },
                { columnName: 'mc_product_name', align: 'center' },
                // { columnName: 'created_qty', align: 'center', width: 150 },
                // { columnName: 'canceled_qty', align: 'center', width: 150 },
                {
                    columnName: 'agent_submitted_qty',
                    align: 'center',
                    width: 110
                },
                { columnName: 'op_approved_qty', align: 'center', width: 110 },
                { columnName: 'op_rejected_qty', align: 'center', width: 110 },
                { columnName: 'mc_approved_qty', align: 'center', width: 110 },
                { columnName: 'mc_rejected_qty', align: 'center', width: 110 }
                // { columnName: 'other', align: 'center', width: 150 }
            ],
            grid2TotalCount: 0,
            grid2CurrentPage: 0,

            pageSize: 15,
            loading: true,
            search_date_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .subtract(14, 'day')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_date_end: moment()
                .tz('Asia/Ho_Chi_Minh')
                .endOf('day')
                .format('YYYY-MM-DD')
        };

        this.changeGrid1CurrentPage = this.changeGrid1CurrentPage.bind(this);
        this.changeGrid2CurrentPage = this.changeGrid2CurrentPage.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.loadProducts();
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    loadProducts() {
        let queryString = `${productsURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var merchants = [];
                    merchants.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = merchants.some(el => el.code === p.mc_code);
                        if (found) return;
                        merchants.push({ code: p.mc_code, name: p.mc_code });
                    });

                    var products = [];
                    products.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = products.some(el => el.code === p.code);
                        if (found) return;
                        products.push(p);
                    });

                    this.setState({
                        products: products,
                        merchants: merchants
                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }
    changeGrid1CurrentPage(page) {
        this.setState(
            {
                loading: true,
                grid1CurrentPage: page
            },
            () => {
                this.loadGrid1Data();
            }
        );
    }

    changeGrid2CurrentPage(page) {
        this.setState(
            {
                loading: true,
                grid2CurrentPage: page
            },
            () => {
                this.loadGrid2Data();
            }
        );
    }

    queryChartString() {
        const {
            search_date_start,
            search_date_end,
            searchProduct,
            searchAgentName,
            searchRegion,
            searchSubRegion
        } = this.state;

        let queryString = `${URL}?orderby=report_date&asc=true&group_field1=report_date`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;

        if (searchAgentName) queryString = `${queryString}&agent_name=${searchAgentName}`;
        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadChartData = conditions => {
        const queryString = this.queryChartString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatChartValue(data.data[i]);
                    }

                    this.setState({
                        chartData: data.data,
                        loading: false
                    });
                } else {
                    this.setState({
                        chartData: [],
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };
    queryGrid1String() {
        const {
            pageSize,
            grid1CurrentPage,
            search_date_start,
            search_date_end,
            searchProduct,
            searchMerchant,
            searchAgentName,
            searchRegion,
            searchSubRegion
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${grid1CurrentPage +
            1}&orderby=report_date&asc=false&group_field1=sub_region&group_field2=agent_id`;

        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchAgentName) queryString = `${queryString}&agent_name=${searchAgentName}`;
        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadGrid1Data = conditions => {
        const queryString = this.queryGrid1String();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(JSON.stringify(data));
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        grid1Data: data.data,
                        grid1TotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        grid1Data: [],
                        grid1TotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() =>
                this.setState({
                    grid1Data: [],
                    loading: false
                })
            );
    };

    queryGrid2String() {
        const {
            pageSize,
            grid2CurrentPage,
            search_date_start,
            search_date_end,
            searchProduct,
            searchMerchant,
            searchAgentName,
            searchRegion,
            searchSubRegion
        } = this.state;
        let queryString = `${URL}?pagesize=${pageSize}&page=${grid2CurrentPage +
            1}&orderby=report_date&asc=false&group_field1=sub_region&group_field2=agent_id&group_field3=mc_product_code`;

        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchAgentName) queryString = `${queryString}&agent_name=${searchAgentName}`;
        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadGrid2Data = conditions => {
        const queryString = this.queryGrid2String();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        grid2Data: data.data,
                        grid2TotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        grid2Data: [],
                        grid2TotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() =>
                this.setState({
                    grid2Data: [],
                    grid2TotalCount: 0,
                    loading: false
                })
            );
    };

    loadData = conditions => {
        this.loadChartData();
        this.loadGrid1Data();
        this.loadGrid2Data();
    };
    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            chartData,
            grid1Data,
            grid1Columns,
            grid1ColumnConfig,
            grid1CurrentPage,
            grid1TotalCount,

            grid2Data,
            grid2Columns,
            grid2ColumnConfig,
            grid2CurrentPage,
            grid2TotalCount,

            pageSize,
            loading
        } = this.state;

        return (
            <div>
                <form className={classes.search_container} noValidate autoComplete="off">
                    <TextField
                        id="search_date_start"
                        label="Từ ngày"
                        className={classes.search_field}
                        value={this.state.search_date_start}
                        onChange={this.handleChange('search_date_start')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <TextField
                        id="search_date_end"
                        label="Đến ngày"
                        className={classes.search_field}
                        value={this.state.search_date_end}
                        onChange={this.handleChange('search_date_end')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        id="search_region"
                        select
                        label="Miền"
                        className={classes.search_field}
                        value={this.state.searchRegion}
                        onChange={this.handleChange('searchRegion')}
                        margin="normal"
                        InputLabelProps={{ shrink: this.state.searchRegion ? true : false }}
                    >
                        <MenuItem value={null}></MenuItem>
                        <MenuItem value="MIỀN NAM">MIỀN NAM</MenuItem>
                        <MenuItem value="MIỀN BẮC">MIỀN BẮC</MenuItem>
                    </TextField>
                    <TextField
                        id="search_sub_region"
                        select
                        label="Vùng"
                        className={classes.search_field}
                        value={this.state.searchSubRegion}
                        onChange={this.handleChange('searchSubRegion')}
                        margin="normal"
                        InputLabelProps={{ shrink: this.state.searchSubRegion ? true : false }}
                    >
                        <MenuItem value={null}></MenuItem>
                        <MenuItem value="N1">N1</MenuItem>
                        <MenuItem value="N2">N2</MenuItem>
                        <MenuItem value="N3">N3</MenuItem>
                        <MenuItem value="N4">N4</MenuItem>
                        <MenuItem value="N5">N5</MenuItem>
                        <MenuItem value="N6">N6</MenuItem>
                        <MenuItem value="N7">N7</MenuItem>
                        <MenuItem value="N8">N8</MenuItem>
                        <MenuItem value="N9">N9</MenuItem>
                        <MenuItem value="N10">N10</MenuItem>
                        <MenuItem value="B1">B1</MenuItem>
                        <MenuItem value="B2">B2</MenuItem>
                        <MenuItem value="B3">B3</MenuItem>
                        <MenuItem value="B4">B4</MenuItem>
                        <MenuItem value="B5">B5</MenuItem>
                        <MenuItem value="B6">B6</MenuItem>
                        <MenuItem value="B7">B7</MenuItem>
                        <MenuItem value="B8">B8</MenuItem>
                        <MenuItem value="B9">B9</MenuItem>
                        <MenuItem value="B10">B10</MenuItem>
                    </TextField>


                    <TextField
                        id="agent_name_id"
                        label="DSA"
                        type="search"
                        className={classes.search_agent_field}
                        value={this.state.searchAgentName}
                        onChange={this.handleChange('searchAgentName')}
                        margin="normal"
                    />
                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="merchantId">Đối tác</InputLabel>
                        <Select
                            value={this.state.searchMerchant}
                            onChange={this.handleChange('searchMerchant')}
                            inputProps={{
                                name: 'merchant',
                                id: 'merchantId'
                            }}
                        >
                            {this.state.merchants.map(item => (
                                <MenuItem value={item.code}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.search_product_field}>
                        <InputLabel htmlFor="productId">Sản phẩm</InputLabel>
                        <Select
                            value={this.state.searchProduct}
                            onChange={this.handleChange('searchProduct')}
                            inputProps={{
                                name: 'product',
                                id: 'productId'
                            }}
                        >
                            {this.state.products.map(pro => (
                                <MenuItem value={pro.code}>
                                    {pro.code && (
                                        <div>
                                            {pro.mc_code} - {pro.name}
                                        </div>
                                    )}
                                    {!pro.code && <div>{pro.name}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
                <Chart data={chartData} className={classes.chart} height="300">
                    <ArgumentAxis />
                    <ArgumentScale />
                    <ValueAxis />
                    <Legend position="bottom" rootComponent={LegendRoot} labelComponent={LegendLabel} />
                    <BarSeries argumentField="report_date" valueField="agent_submitted_qty" name="Đẩy hồ sơ" />
                    <BarSeries argumentField="report_date" valueField="mc_rejected_qty" name="Đối tác từ chối" />
                    <BarSeries argumentField="report_date" valueField="mc_approved_qty" name="Đối tác Duyệt" />
                    <Stack />
                </Chart>
                <Paper className={classes.grid_Container}>
                    <Grid rows={grid1Data} columns={grid1Columns} getRowId={getGrid1RowId}>
                        <PagingState
                            currentPage={grid1CurrentPage}
                            onCurrentPageChange={this.changeGrid1CurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={grid1TotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={grid1ColumnConfig}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>{' '}
                <Typography variant="h5" gutterBottom component="h2" style={{ marginTop: 30, marginLeft: 10 }}>
                    Chi tiết theo sản phẩm
                </Typography>
                <Paper className={classes.grid_Container}>
                    <Grid rows={grid2Data} columns={grid2Columns} getRowId={getGrid2RowId}>
                        <PagingState
                            currentPage={grid2CurrentPage}
                            onCurrentPageChange={this.changeGrid2CurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={grid2TotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={grid2ColumnConfig}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const rootStyles = {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row'
};
const LegendRoot = props => <Legend.Root {...props} style={rootStyles} />;

const defaultLabelStyles = {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    fontSize: '14px'
};
const LegendLabel = props => (
    <div {...props} style={defaultLabelStyles}>
        {props.text}
    </div>
);

const getGrid1RowId = row => row.report_date + row.sub_region +  row.agent_id;
const getGrid2RowId = row => row.report_date + row.agent_id + row.mc_product_code;

DailySummaryByDSA.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 150,
        minWidth: 120
    },
    search_agent_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 300,
        minWidth: 300
    },
    search_product_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 400,
        minWidth: 300
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    },
    chart: {
        marginTop: 30
    }
});

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                textAlign: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withStyles(styles)(DailySummaryByDSA);
