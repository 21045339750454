import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from 'moment-timezone';

import cs from '../../const.js';

const URL = cs.BaseURL + '/agent/location/statistic';

function formatValue(value) {
    return value;
}

class AgentLocationStatistic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'region', title: 'Miền' },
                { name: 'sub_region', title: 'Vùng' },
                { name: 'totalDSA', title: 'DSA' },
                { name: 'totalAllocatedDSA', title: 'DSA đã phân quyền' },
                { name: 'totalAllocation', title: 'Lượt phân quyền' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'region', align: 'center', width: 140 },
                { columnName: 'sub_region', align: 'center', width: 140 },
                { columnName: 'totalDSA', align: 'center', width: 150 },
                { columnName: 'totalAllocatedDSA', align: 'center', width: 150 },
                { columnName: 'totalAllocation', align: 'center', width: 150 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);

        this.loadData();
    }

    componentDidMount() {
        this.loadData();
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            // sorting,
            searchRegion,
            searchSubRegion,
            searchState,
            searchDistrict,
            searchWard
        } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;

        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;
        if (searchState) queryString = `${queryString}&adr_state=${searchState}`;
        if (searchDistrict) queryString = `${queryString}&adr_district=${searchDistrict}`;
        if (searchWard) queryString = `${queryString}&adr_ward=${searchWard}`;

        // const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });

        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data && data.data instanceof Array && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.state.currentPage = 0;
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }
        return (
            <div>
                {/* <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Làm mới"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>LÀM MỚI</span>
                </Button> */}

                <ExpansionPanel>
                    <ExpansionPanelSummary className={classes.search_heading} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                        
                            <TextField
                                id="search_region"
                                select
                                label="Miền"
                                className={classes.search_field}
                                value={this.state.searchRegion}
                                onChange={this.handleChange('searchRegion')}
                                margin="normal"
                                InputLabelProps={{ shrink: this.state.searchRegion ? true : false }}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value="MIỀN NAM">MIỀN NAM</MenuItem>
                                <MenuItem value="MIỀN BẮC">MIỀN BẮC</MenuItem>
                            </TextField>
                            <TextField
                                id="search_sub_region"
                                select
                                label="Vùng"
                                className={classes.search_field}
                                value={this.state.searchSubRegion}
                                onChange={this.handleChange('searchSubRegion')}
                                margin="normal"
                                InputLabelProps={{ shrink: this.state.searchSubRegion ? true : false }}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value="N1">N1</MenuItem>
                                <MenuItem value="N2">N2</MenuItem>
                                <MenuItem value="N3">N3</MenuItem>
                                <MenuItem value="N4">N4</MenuItem>
                                <MenuItem value="N5">N5</MenuItem>
                                <MenuItem value="N6">N6</MenuItem>
                                <MenuItem value="N7">N7</MenuItem>
                                <MenuItem value="N8">N8</MenuItem>
                                <MenuItem value="N9">N9</MenuItem>
                                <MenuItem value="N10">N10</MenuItem>
                                <MenuItem value="B1">B1</MenuItem>
                                <MenuItem value="B2">B2</MenuItem>
                                <MenuItem value="B3">B3</MenuItem>
                                <MenuItem value="B4">B4</MenuItem>
                                <MenuItem value="B5">B5</MenuItem>
                                <MenuItem value="B6">B6</MenuItem>
                                <MenuItem value="B7">B7</MenuItem>
                                <MenuItem value="B8">B8</MenuItem>
                                <MenuItem value="B9">B9</MenuItem>
                                <MenuItem value="B10">B10</MenuItem>
                            </TextField>
                            <TextField
                                id="adr_state"
                                label="Tỉnh/thành phố"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchState}
                                onChange={this.handleChange('searchState')}
                                margin="normal"
                            />
                            <TextField
                                id="adr_district"
                                label="Quận/huyện"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchDistrict}
                                onChange={this.handleChange('searchDistrict')}
                                margin="normal"
                            />
                            <TextField
                                id="adr_ward"
                                label="Phường/xã"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchWard}
                                onChange={this.handleChange('searchWard')}
                                margin="normal"
                            />
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            // showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const getRowId = row => row.sub_region;
AgentLocationStatistic.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_adr_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: '100%',
        minWidth: 120
    }
});

const CellComponent = props => {
    const { column } = props;
    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2
            }}
        />
    );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(AgentLocationStatistic));
