import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from 'moment-timezone';

import cs from '../../const.js';
import DeleteAgentLocation from './DeleteAgentLocation';
import CreateAgentLocation from './CreateAgentLocation';

const URL = cs.BaseURL + '/agent/location/list';
const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';

function formatValue(value) {
    if (value && value.adr_district) {
        value.adr_district = value.adr_district.replace('Thành phố', 'TP').trim();
        value.adr_district = value.adr_district.replace('Huyện', '').trim();
    }

    if (value && value.adr_state) {
        value.adr_state = value.adr_state.replace('Thành phố', 'TP').trim();
        value.adr_state = value.adr_state.replace('Tỉnh', '').trim();
    }
    if (value && value.is_default) value.is_default = 'Mặc định';
    else value.is_default = '';
    return value;
}

const isOPLeader = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_Leader;
};
const isOPManager = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_Manager;
};

class AgentLocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            states: [],
            districts: [],
            searchState: '',
            searchDistrict: '',
            columns: [
                { name: 'sub_region', title: ' ' },
                { name: 'code', title: 'Code' },
                { name: 'name', title: 'Họ và tên' },
                // { name: 'phone', title: 'SĐT' },
                { name: 'adr_state', title: 'Tỉnh/TP' },
                { name: 'adr_district', title: 'Quận/Huyện' },
                { name: 'adr_ward', title: 'Phường/Xã' },
                { name: 'is_default', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'sub_region', align: 'center', width: 50 },
                { columnName: 'code', align: 'center', width: 110 },
                { columnName: 'name', align: 'left' },
                // { columnName: 'phone', align: 'center', width: 120 },
                { columnName: 'adr_state', align: 'center', width: 150 },
                { columnName: 'adr_district', align: 'center', width: 150 },
                { columnName: 'adr_ward', align: 'center', width: 150 },
                { columnName: 'is_default', align: 'center', width: 110 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            showImportLocation: false
        };

        if (isOPLeader()) {
            this.state.columns.push({ name: 'action', title: ' ' });
            this.state.tableColumnExtensions.push({ columnName: 'action', align: 'center', width: 50 });
        }

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);

        this.loadData();
        this.loadStates();
    }

    componentDidMount() {
        this.loadData();
    }

    loadStates() {
        let queryString = `${statesURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        states: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        let queryString = `${districtsURL}?state_code=${this.state.searchState}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_district: '', adr_district_code: null });
                    this.setState({
                        districts: data.data
                    });
                } else {
                    this.setState({
                        districts: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => { });
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            searchName,
            searchPhone,
            searchNationalId,
            searchRegion,
            searchSubRegion,
            searchState,
            searchDistrict
        } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;

        if (searchName) queryString = `${queryString}&name=${searchName}`;
        if (searchPhone) queryString = `${queryString}&phone=${searchPhone}`;
        if (searchNationalId) queryString = `${queryString}&national_id=${searchNationalId}`;
        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;

        if (searchState) queryString = `${queryString}&adr_state_code=${searchState}`;
        if (searchDistrict) queryString = `${queryString}&adr_district_code=${searchDistrict}`;

        // const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });

        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.state.currentPage = 0;
                if (name == 'searchState') this.loadDistricts();
                this.loadData();
            }
        );
    };

    handleShowImportLocation = () => {
        this.setState({ showImportLocation: true });
    };
    handleCloseImportLocation = () => {
        this.setState({ showImportLocation: false });
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }
        return (
            <div>
                {/* <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Làm mới"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>LÀM MỚI</span>
                </Button> */}
                {isOPLeader() && (
                    <Button
                        color="inherit"
                        iconStyle={{
                            height: 200,
                            width: 200,
                            fontSize: '48px'
                        }}
                        aria-label="Tạo phân quyền mới"
                        component={Link}
                        to={'/agent_location/create'}
                    >
                        <Icon>person_add</Icon>
                        <span style={{ marginLeft: 10 }}>Tạo phân quyền mới</span>
                    </Button>
                )}

                {/* <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import DSA"
                    onClick={this.handleShowImportLocation}
                >
                    <Icon>cloud_upload</Icon>
                    <span style={{ marginLeft: 10 }}>Import</span>
                </Button> */}

                <ExpansionPanel>
                    <ExpansionPanelSummary className={classes.search_heading} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="agent_name"
                                label="Họ và tên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchName}
                                onChange={this.handleChange('searchName')}
                                margin="normal"
                            />
                            <TextField
                                id="agent_phone"
                                label="Số đ.thoại"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchPhone}
                                onChange={this.handleChange('searchPhone')}
                                margin="normal"
                            />
                            <TextField
                                id="agent_national_id"
                                label="CMND"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchNationalId}
                                onChange={this.handleChange('searchNationalId')}
                                margin="normal"
                            />
                            <TextField
                                id="search_region"
                                select
                                label="Miền"
                                className={classes.search_field}
                                value={this.state.searchRegion}
                                onChange={this.handleChange('searchRegion')}
                                margin="normal"
                                InputLabelProps={{ shrink: this.state.searchRegion ? true : false }}
                            >
                                <MenuItem value={null}></MenuItem>
                                {/* <MenuItem value="MIỀN NAM">MIỀN NAM</MenuItem>
                                <MenuItem value="MIỀN BẮC">MIỀN BẮC</MenuItem> */}
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </TextField>
                            <TextField
                                id="search_sub_region"
                                select
                                label="Vùng"
                                className={classes.search_field}
                                value={this.state.searchSubRegion}
                                onChange={this.handleChange('searchSubRegion')}
                                margin="normal"
                                InputLabelProps={{ shrink: this.state.searchSubRegion ? true : false }}
                            >
                                <MenuItem value={null}></MenuItem>
                                {/* <MenuItem value="N1">N1</MenuItem>
                                <MenuItem value="N2">N2</MenuItem>
                                <MenuItem value="N3">N3</MenuItem>
                                <MenuItem value="N4">N4</MenuItem>
                                <MenuItem value="N5">N5</MenuItem>
                                <MenuItem value="N6">N6</MenuItem>
                                <MenuItem value="N7">N7</MenuItem>
                                <MenuItem value="N8">N8</MenuItem>
                                <MenuItem value="N9">N9</MenuItem>
                                <MenuItem value="N10">N10</MenuItem>
                                <MenuItem value="B1">B1</MenuItem>
                                <MenuItem value="B2">B2</MenuItem>
                                <MenuItem value="B3">B3</MenuItem>
                                <MenuItem value="B4">B4</MenuItem>
                                <MenuItem value="B5">B5</MenuItem>
                                <MenuItem value="B6">B6</MenuItem>
                                <MenuItem value="B7">B7</MenuItem>
                                <MenuItem value="B8">B8</MenuItem>
                                <MenuItem value="B9">B9</MenuItem>
                                <MenuItem value="B10">B10</MenuItem> */}
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>{pro.state_name}</MenuItem>
                                ))}
                            </TextField>
                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="searchState_id">Tỉnh/thành phố</InputLabel>
                                <Select
                                    value={this.state.searchState}
                                    onChange={this.handleChange('searchState')}
                                    inputProps={{
                                        name: 'searchState',
                                        id: 'searchState_id'
                                    }}
                                >
                                    {this.state.states.map(pro => (
                                        <MenuItem value={pro.state_code}>{pro.state_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>{' '}
                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="searchDistrict_id">Quận / huyện</InputLabel>
                                <Select
                                    value={this.state.searchDistrict}
                                    onChange={this.handleChange('searchDistrict')}
                                    inputProps={{
                                        name: 'searchDistrict',
                                        id: 'searchDistrict_id'
                                    }}
                                >
                                    {this.state.districts.map(pro => (
                                        <MenuItem value={pro.district_code}>{pro.district_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>{' '}
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            // showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>

                <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                    <Route exact path="/agent_location/delete/:loc_id" component={DeleteAgentLocation} />
                    <Route exact path="/agent_location/create/" component={CreateAgentLocation} />
                    {/* <ImportLocation open={this.state.showImportLocation} onClose={this.handleCloseImportLocation} />  */}
                </ToastProvider>
            </div>
        );
    }
}

const getRowId = row => row.id;
AgentLocationList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_adr_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: '100%',
        minWidth: 120
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 1,
                    justifyContent: 'center'
                }}
            >
                <span>
                    {isOPLeader() && (
                        <Button
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            color="secondary"
                            component={Link}
                            to={'/agent_location/delete/' + this.props.row.id}
                        >
                            <Icon>remove_circle</Icon>
                        </Button>
                    )}
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2
            }}
        />
    );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};
const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(AgentLocationList));
