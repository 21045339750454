import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const productsURL = cs.BaseURL + '/product/list';
const usersURL = cs.BaseURL + '/user/list';

const createOPAllocationURL = cs.BaseURL + '/op-allocation/create';
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class CreateOPAllocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            users: [],
            mc_product_code: '',
            allocation_weight:100
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadProducts();
        this.loadUsers();
    }

    handleDialogAgree = () => {
        if (!this.state.user_id) {
            window.alert('Please input information !');
            return;
        }
        let queryString = `${createOPAllocationURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                user_id: this.state.user_id,
                mc_product_code: this.state.mc_product_code,
                allocation_weight:this.state.allocation_weight
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Tạo thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/op_allocation',
                    state: { reload: true }
                });
            })
            .catch(() => {});
    };

    loadProducts() {
        let queryString = `${productsURL}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ name: '', code: null });
                    this.setState({
                        products: data.data
                    });
                } else {
                    this.setState({
                        products: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    loadUsers() {
        let queryString = `${usersURL}?orderby=name&type=${cs.UserType_OP}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ name: '', id: null });
                    this.setState({
                        users: data.data
                    });
                } else {
                    this.setState({
                        users: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    users: []
                });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {}
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="create_user_form-dialog-title">Tạo cấu hình phân bổ mới</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                id="create_user_id"
                                select
                                label="Nhân viên"
                                value={this.state.user_id}
                                onChange={this.handleChange('user_id')}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            >
                                {this.state.users.map(user => {
                                    if (user.id)
                                        return (
                                            <MenuItem value={user.id}>
                                                {user.fullname} ({user.name})
                                            </MenuItem>
                                        );
                                })}
                            </TextField>
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                id="create_user_product"
                                select
                                required={true}
                                label="Sản phẩm"
                                value={this.state.mc_product_code}
                                onChange={this.handleChange('mc_product_code')}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            >
                                {this.state.products.map(pro => {
                                    if (pro.id)
                                        return (
                                            <MenuItem value={pro.code}>
                                                {pro.mc_code} - {pro.name}
                                            </MenuItem>
                                        );
                                })}
                            </TextField>
                        </FormControl>

                        <TextField
                            fullWidth
                            margin="dense"
                            id="op_allocation_weight"
                            required={true}
                            value={this.state.allocation_weight}
                            onChange={this.handleChange('allocation_weight')}
                            label="Tỷ trọng"
                            type="number"
                        />


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateOPAllocation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateOPAllocation));
