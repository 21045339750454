import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';
import { DropzoneArea } from 'material-ui-dropzone';


const allocateKPIURL = cs.BaseURL + '/telesale/lead/kpi';

// const allocateKPIURL1 = cs.BaseURL + '/telesale/lead/kpi/merchant';
// const allocateKPIURL2 = cs.BaseURL + '/telesale/lead/kpi/teamname';
// const allocateKPIURL3 = cs.BaseURL + '/telesale/lead/kpi/product';

const mcLeaderURL = cs.BaseURL + '/telesale/lead/merchant-leader';
const mcProductURL = cs.BaseURL + '/telesale/lead/merchant-product/list';

const agentURL = cs.BaseURL + '/telesale/lead/agent/list';
const productURL = cs.BaseURL + '/telesale/lead/agent-product/list';
const styles = theme => ({});

const isMarketingMerchantAcc = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    console.log(user, userrole);
    return (
        userrole === cs.Role_Marketing_Merchant_Leader ||
        userrole === cs.Role_Marketing_Merchant_Manager ||
        userrole === cs.Role_Marketing_Merchant_User
    );
};

const isTelesaleOP = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Hub_Leader
        || userrole === cs.Role_Telesale_Hub_Manager
    );
}

const isTelesaleMerchantManager = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Merchant_Manager
        //|| userrole === cs.Role_Telesale_Hub_Manager
    );
}

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));


class AllocateKPI extends React.Component {
    constructor(props) {
        super(props);
        const currentYear = (new Date()).getFullYear();

        this.state = {
            type: 1,
            id: this.props.match.params.id,
            phone: this.props.match.params.phone,
            year: '',
            month: '',
            kpi_plan: '',
            // agent_code: '',
            // agent_name: '',
            // agent_phone: '',
            team_name: '',

            mc_product_code: '',
            mc_product_name: '',



            products: [],
            agents: [],
            leaders: [],
            years: range(currentYear, currentYear - 50, -1),
            months: Array.from(new Array(12), (x, i) => i + 1)
        };
        // if (!isTelesaleOP()) {
        //     window.location.href = "/";
        //     return;
        // }
        console.log("HERE")
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        this.loadAgents();
        if (isTelesaleMerchantManager()) {
            this.loadLeaders();
        }
        //this.loadLeaders();
        this.loadProducts();
    }

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    loadAgents() {
        let queryString = `${agentURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        agents: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadLeaders() {
        let queryString = `${mcLeaderURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        leaders: data.data
                    });
                } else {
                    this.setState({
                        leaders: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    leaders: []
                });
            });
    }


    loadProducts() {
        // let queryString = `${mcProductURL}?agent_id=${id}`;
        let queryString = `${mcProductURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ name: '', code: '' });
                    this.setState({
                        products: data.data
                    });
                } else {
                    this.setState({
                        products: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state);

        if (this.state.kpi_plan && isNaN(this.state.kpi_plan)) {
            window.alert('Incorrect amount !');
            return;
        }

        if (!this.state.year || !this.state.month
            // || !this.state.agent_code || !this.state.agent_name
            // || !this.state.agent_phone || !this.state.mc_product_code
            // || !this.state.mc_product_name || !this.state.team_name
            || !this.state.kpi_plan
            // || (this.state.type == 2 && this.state.team_name)
        ) {
            window.alert('Please input information !');
            return;
        }

        if (this.state.type === 2 && isTelesaleMerchantManager() && !this.state.team_name) {
            window.alert('Please input information 2!');
            return;
        }

        if (this.state.type === 3 && (!this.state.mc_product_code || !this.state.mc_product_name)) {
            window.alert('Please input information 3!');
            return;
        }


        let requestBody = {
            year: this.state.year,
            month: this.state.month,
            // agent_code: this.state.agent_code,
            // agent_name: this.state.agent_name,
            // agent_phone: this.state.agent_phone,
            // mc_product_code: this.state.mc_product_code,
            // mc_product_name: this.state.mc_product_name,
            // team_name: this.state.team_name,
            kpi_plan: this.state.kpi_plan
        }
        // console.log(this.state);


        // if (this.state.priority < 0 || this.state.priority > 10) {
        //     window.alert('Incorrect priority value !');
        //     return;
        // }

        // let state = this.state.states.filter((el) => el.state_code === this.state.contact_province)
        // let district = this.state.districts.filter((el) => el.district_code === this.state.contact_district)
        // let ward = this.state.wards.filter((el) => el.ward_code === this.state.contact_commune)
        // console.log(state);
        // console.log(district);
        // console.log(ward);
        let queryString = `${allocateKPIURL}`;
        switch (this.state.type) {
            case 1:
                queryString += '/merchant';

                break;
            case 2:
                queryString += '/teamname';
                requestBody.team_name = this.state.team_name
                break;
            case 3:
                queryString += '/product';
                requestBody.mc_product_name = this.state.mc_product_name;
                requestBody.mc_product_code = this.state.mc_product_code;
                break;
            default:
                break;
        }

        console.log(requestBody);

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(requestBody),

            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed.code) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('KPI is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/kpi',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        // this.setState({
        //     [name]: event.target.value
        // });
        console.log(event.target.value, event.target.checked);
        if (name == "like_able" || name == "comment_able") this.setState({ [name]: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == "agent_code") {
                        let agent = this.state.agents.filter((el) => el.code === event.target.value)
                        console.log(agent);
                        this.loadProducts(agent[0].id);
                        this.setState({
                            mc_product_code: '',
                            mc_product_name: '',
                            agent_name: agent[0].name,
                            agent_phone: agent[0].phone,
                            team_name: agent[0].team_name || ""
                        })
                    }
                    if (name == "mc_product_code") {
                        let product = this.state.products.filter((el) => el.code === event.target.value)
                        this.setState({ mc_product_name: product[0].name })
                    }

                }
            );
    };



    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                    <DialogTitle id="create_merchant_form-dialog-title">Phân KPI theo</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Type </InputLabel>
                            <Select
                                value={this.state.type}
                                onChange={this.handleChange('type')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {isTelesaleMerchantManager() && <MenuItem value={1}>Merchant</MenuItem>}
                                <MenuItem value={2}>Team name</MenuItem>
                                <MenuItem value={3}>Product</MenuItem>

                                {/* {this.state.agents.map(item => (
                                    <MenuItem value={item.code}>{item.name}</MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="priority"
                            required={true}
                            value={this.state.kpi_plan}
                            onChange={this.handleChange('kpi_plan')}
                            label="KPI cho tháng (KH đồng ý/tháng)"
                            type='number'
                        //InputProps={{ inputProps: { min: 0, max: 10 } }}
                        />


                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >



                            <Grid item xs={6}>
                                {/* <div fullWidth className={classes.create_button}>
                                    Like
                                    <Switch
                                        checked={this.state.like_able}
                                        value={this.state.like_able}
                                        onChange={this.handleChange('like_able')}
                                        color="primary"
                                    />
                                    {this.state.like_able ? 'Có' : 'Không'}
                                </div> */}
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Month </InputLabel>
                                    <Select
                                        value={this.state.month}
                                        onChange={this.handleChange('month')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >
                                        {/* <MenuItem value="{item}">e</MenuItem> */}
                                        {this.state.months.map(item => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Year </InputLabel>
                                    <Select
                                        value={this.state.year}
                                        onChange={this.handleChange('year')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >
                                        {/* <MenuItem value="{item}">e</MenuItem> */}
                                        {this.state.years.map(item => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* <TextField
                            disabled
                            fullWidth
                            //autoFocus
                            margin="dense"
                            id="title"
                            required={true}
                            value={this.state.agent_name}
                            onChange={this.handleChange('agent_name')}
                            label="Agent name "
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            //autoFocus
                            margin="dense"
                            id="agent_phone"
                            required={true}
                            value={this.state.agent_phone}
                            onChange={this.handleChange('agent_phone')}
                            label="Agent phone"
                            type='text'
                        /> */}
                        {
                            this.state.type === 2 && isTelesaleMerchantManager() &&

                            <FormControl fullWidth>
                                <InputLabel htmlFor="merchantId">Team name </InputLabel>
                                <Select
                                    value={this.state.team_name}
                                    onChange={this.handleChange('team_name')}
                                    inputProps={{
                                        name: 'package_type',
                                        id: 'package_type'
                                    }}
                                >
                                    {/* <MenuItem value="{item}">e</MenuItem> */}
                                    {this.state.leaders.map(item => (
                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }

                        {this.state.type === 3 &&
                            <FormControl fullWidth>
                                <InputLabel htmlFor="merchantId">Product name </InputLabel>
                                <Select
                                    value={this.state.mc_product_code}
                                    onChange={this.handleChange('mc_product_code')}
                                    inputProps={{
                                        name: 'package_type',
                                        id: 'package_type'
                                    }}
                                >
                                    {/* <MenuItem value="{item}">e</MenuItem> */}
                                    {this.state.products.map(item => (
                                        <MenuItem value={item.code}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                        {/* <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.gridContainer}
                        >
                            <Grid item gutterBottom>
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    dropzoneText={
                                        this.state.file && this.state.file.name
                                            ? this.state.file.name
                                            : 'Upload file ảnh bài viết (*)'
                                    }
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviewsInDropzone={false}
                                    onChange={this.handleDropZoneChange}
                                    dropZoneClass={classes.dropZone}
                                />
                            </Grid>
                            <Grid item>
                                <div></div>
                            </Grid>
                        </Grid> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Create KPI
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AllocateKPI.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(AllocateKPI));
