import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { isMobile } from 'react-device-detect';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import Loadable from 'react-loadable';
import cs from './const.js';
import Home from './components/Home';

const LazyLoadLogin = Loadable({
    loader: () => import('./components/Login'),
    loading: () => <div>Loading...</div>
});

class App extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        loadCSS('https://use.fontawesome.com/releases/v5.1.0/css/all.css', document.querySelector('#insertion-point-jss'));
    }

    render() {
        if (isMobile) {
            return (
                <div
                    style={{
                        width: '100%',
                        marginTop: 150,
                        textAlign: 'center'
                    }}
                >
                    <Typography fullWidth variant="subtitle1">
                        Vui lòng xem trang web trên máy tính !
                    </Typography>
                </div>
            );
        }

        var token = localStorage.getItem(cs.System_Code + '-op-token');
        if (!token || token == null || token == 'null' || token == undefined) {
            return <LazyLoadLogin />;
        } else {
            return <Home />;
        }
    }
}

export default App;
