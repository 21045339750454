import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const productsURL = cs.BaseURL + '/product/list';
const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';
const orderDetailURL = cs.BaseURL + '/order/detail';
const agentURL = cs.BaseURL + '/order/get-suggest-agent-list?order_code='
const AssignAgentURL = cs.BaseURL + '/order/manual-assign-agent';
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class AssignAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCode: this.props.match.params.orderCode,
            agent_id: '',
            agentList: [],
        };


        this.loadAgents = this.loadAgents.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        // this.loadProducts();
        // this.loadStates();
        // this.loadOrder();
        this.loadAgents();
    }

    resetState = () => {
        this.setState({
            agent_id: ''
        })
    }

    handleDialogAgree = () => {
        if (!this.state.agent_id ){
            return;
        }

        let queryString = `${AssignAgentURL}`;

        var body = { order_code: this.state.orderCode, agent_id: this.state.agent_id };

        console.log(body);
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Assign hồ sơ thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }

                if (
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.previous
                )
                    this.props.history.push({
                        pathname: this.props.history.location.state.previous,
                        state: { reload: true }
                    });

                this.resetState();
            })
            .catch(() => {
                this.resetState();
            });
    };


    loadAgents() {
        let queryString = `${agentURL}${this.state.orderCode}`;
        // var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-op-states'));
        // if (
        //     lastData &&
        //     queryString === lastData.query &&
        //     parseInt(lastData.query_at) + 60 * 60 * 24 * 30 > moment().unix()
        // ) {
        //     this.setState({ states: lastData.states });
        //     return;
        // }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    console.log(data.data);
                    this.setState({
                        agentList: data.data
                    });

                    localStorage.setItem(
                        cs.System_Code + '-op-states',
                        JSON.stringify({
                            states: data.data,
                            query: queryString,
                            query_at: moment().unix()
                        })
                    );
                } else {
                    this.setState({
                        states: []
                    });
                    localStorage.setItem(cs.System_Code + '-op-states', null);
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                localStorage.setItem(cs.System_Code + '-op-states', null);
                this.setState({
                    states: []
                });
            });
    }



    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == 'cus_adr_state_code') this.loadDistricts();
                    if (name == 'cus_adr_district_code') this.loadWards();
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="update_order_form-dialog-title">
                    PHÂN CÔNG DSA THEO GỢI Ý

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                           
                        </DialogContentText>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="cus_adr_state_id">
                                Agent (*)
                                    </InputLabel>
                            <Select
                                value={this.state.agent_id}
                                onChange={this.handleChange('agent_id')}
                                inputProps={{
                                    name: 'cus_adr_state_code',
                                    id: 'cus_adr_state_id'
                                }}
                            >
                                {this.state.agentList.map(el => (
                                    <MenuItem value={el.id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Cập nhật
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AssignAgent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(AssignAgent));
