import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import MonthlySummaryByDate from './MonthlySummaryByDate';
import MonthlySummaryByRegion from './MonthlySummaryByRegion';
import MonthlySummaryByDSA from './MonthlySummaryByDSA';
import MonthlySummaryByReject from './MonthlySummaryByReject';

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 2, minHeight: 350 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class MonthlyDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabNum: 0
        };
    }

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };

    render() {
        return (
            <div>


<MonthlySummaryByDate />
                {/* <AppBar position="static">
                    <Tabs value={this.state.tabNum} onChange={this.handleTabChange}>
                        <Tab label="Tổng hợp" />
                        <Tab label="Theo vùng miền" />
                        <Tab label="Theo DSA" />
                        <Tab label="Lý do từ chối" />
                    </Tabs>
                </AppBar>
                <div style={{ minHeight: 400 }}>
                    {this.state.tabNum === 0 && (
                        <TabContainer>
                            <MonthlySummaryByDate />
                        </TabContainer>
                    )}
                    {this.state.tabNum === 1 && (
                        <TabContainer>
                            <MonthlySummaryByRegion />
                        </TabContainer>
                    )}
                    {this.state.tabNum === 2 && (
                        <TabContainer>
                            <MonthlySummaryByDSA />
                        </TabContainer>
                    )}
                    {this.state.tabNum === 3 && (
                        <TabContainer>
                            <MonthlySummaryByReject />
                        </TabContainer>
                    )}
                </div> */}
            </div>
        );
    }
}

const styles = theme => ({});
export default withStyles(styles)(MonthlyDashboard);
