import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import cs from '../../const.js';
import moment from 'moment-timezone';

const URL = cs.BaseURL + '/agent/detail';

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    }
});

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 6 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};
class AgentDetail extends React.Component {
    state = {
        tabNum: 0,

        id: this.props.match.params.id,
        code: '',
        name: '',
        gender: true,
        birthday: moment()
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD'),
        national_id: '',
        national_id_place: '',
        national_id_date: moment()
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD'),
        phone: '',
        email: '',
        adr_state: '',
        adr_district: '',
        adr_ward: '',
        adr_street: '',

        region: '',
        sub_region: '',

        max_order_qty: -1,
        locked: false
    };
    constructor(props) {
        super(props);

        this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?new=true&id=${id}`;
        return queryString;
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data = data.data;

                    this.setState(
                        {
                            code: data.code,
                            name: data.name,

                            gender: data.gender,
                            birthday: moment
                                .unix(data.birthday)
                                .tz('Asia/Ho_Chi_Minh')
                                .format('YYYY-MM-DD'),
                            national_id: data.national_id,
                            national_id_date: moment
                                .unix(data.national_id_date)
                                .tz('Asia/Ho_Chi_Minh')
                                .format('YYYY-MM-DD'),
                            national_id_place: data.national_id_place,
                            phone: data.phone,
                            email: data.email,

                            adr_state: data.adr_state,
                            adr_district: data.adr_district,
                            adr_ward: data.adr_ward,
                            adr_street: data.adr_street,
                            adr_months: data.adr_months,

                            region: data.region,
                            sub_region: data.sub_region,

                            max_order_qty:
                                data.max_order_qty && data.max_order_qty >= 0
                                    ? data.max_order_qty
                                    : -1,
                            locked: data.locked,
                            loading: false
                        },
                        () => {
                        }
                    );
                } else {
                    this.setState({
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        if (name == 'locked') this.setState({ locked: event.target.checked });
        else {
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {}
            );
        }
    };

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };
    render() {
        const { classes } = this.props;
        const { tabNum } = this.state;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
                    <DialogTitle id="edit_form-dialog-title">Thông tin DSA</DialogTitle>
                    <DialogContent>
                        <AppBar position="static">
                            <Tabs value={tabNum} onChange={this.handleTabChange}>
                                <Tab label="Cá nhân" />
                                <Tab label="Địa chỉ" />
                                <Tab label="Quản lý" />
                            </Tabs>
                        </AppBar>
                        {tabNum === 0 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_code"
                                    value={this.state.code}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Code"
                                    type="name"
                                />

                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_name"
                                    value={this.state.name}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Họ và tên"
                                    type="name"
                                />

                                <div fullWidth className={classes.checkbox}>
                                    <Switch
                                        checked={this.state.gender}
                                        value={this.state.gender}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        color="primary"
                                    />

                                    {this.state.gender ? 'Nam' : 'Nữ'}
                                </div>
                                <TextField
                                    fullWidth
                                    id="edit_birthday"
                                    label="Ngày sinh"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={this.state.birthday}
                                    type="date"
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_national_id"
                                    value={this.state.national_id}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="CMND"
                                    type="number"
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_national_id_date"
                                    value={this.state.national_id_date}
                                    label="Ngày cấp"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_national_id_place"
                                    value={this.state.national_id_place}
                                    label="Nơi cấp"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_phone"
                                    value={this.state.phone}
                                    label="Số điện thoại"
                                    type="number"
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_email"
                                    value={this.state.email}
                                    label="email"
                                    type="email"
                                />
                            </TabContainer>
                        )}
                        {tabNum === 1 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_adr_state"
                                    value={this.state.adr_state}
                                    label="Địa chỉ hiện tại - Tỉnh/thành phố"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_adr_district"
                                    value={this.state.adr_district}
                                    label="Địa chỉ hiện tại - Quận / huyện"
                                    type="text"
                                />

                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_adr_ward"
                                    value={this.state.adr_ward}
                                    label="Địa chỉ hiện tại - Xã/Phường"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_adr_street"
                                    value={this.state.adr_street}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Địa chỉ hiện tại - Đường phố"
                                    type="text"
                                />
                            </TabContainer>
                        )}

                        {tabNum === 2 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_region"
                                    value={this.state.region}
                                    label="Miền"
                                    type="text"
                                />

                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    margin="dense"
                                    id="edit_sub_region"
                                    value={this.state.sub_region}
                                    label="Vùng"
                                    type="text"
                                />

                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_max_order_qty"
                                    value={this.state.max_order_qty}
                                    label="Số lượng hồ sơ tối đa tại thời điểm"
                                    type="number"
                                />

                                <div fullWidth className={classes.locked_button}>
                                    <Checkbox
                                        checked={this.state.locked}
                                        value={this.state.locked}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        inputProps={{
                                            'aria-label': 'primary checkbox'
                                        }}
                                    />
                                    Khoá tài khoản
                                </div>
                            </TabContainer>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            THOÁT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AgentDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(AgentDetail));
