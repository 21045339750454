import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const productsURL = cs.BaseURL + '/product/list';
const agentURL = cs.BaseURL + '/agent/simple_detail';
const createAgentProductURL = cs.BaseURL + '/agent/product-assign/create';
const styles = theme => ({});

class CreateAgentProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            agent_code: '',
            mc_product_code: '',
            agent_mc_ref_code: '',
            ref_account: '',
            ref_account_code: '',
            ref_password: '',
            invalid_code: false
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadProducts();
    }

    handleDialogAgree = () => {
        if (!this.state.agent_code || !this.state.mc_product_code) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${createAgentProductURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                agent_code: this.state.agent_code,
                mc_product_code: this.state.mc_product_code,
                agent_mc_ref_code: this.state.agent_mc_ref_code,
                ref_account: this.state.ref_account,
                ref_account_code: this.state.ref_account_code,
                ref_password: this.state.ref_password
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Đã tạo phân quyền !', {
                        appearance: 'success',
                        autoDismiss: true,
pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/agent_product',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleDialogDisAgree = () => {
        this.props.history.push({
            pathname: '/agent_product',
            state: { reload: true }
        });
    };

    loadProducts() {
        let queryString = `${productsURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ mc_product_name: '', mc_product_code: null });
                    this.setState({
                        products: data.data
                    });
                } else {
                    this.setState({
                        products: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    loadAgent() {
        let queryString = `${agentURL}?new=true&code=${this.state.agent_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data = data.data;

                    this.setState({
                        invalid_code: false,
                        agent_code: data.code,
                        agent_name: data.name,
                        agent_national_id: data.national_id,
                        agent_phone: data.phone
                    });
                } else {
                    this.setState({
                        invalid_code: true,
                        agent_name: '',
                        agent_national_id: '',
                        agent_phone: ''
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                if (name == 'agent_code') this.loadAgent();
            }
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="create_agent_loc_form-dialog-title">Tạo phân quyền sản phẩm cho DSA</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_code"
                            error={this.state.invalid_code}
                            helperText={this.state.invalid_code ? 'DSA không tồn tại' : ' '}
                            required={true}
                            value={this.state.agent_code}
                            onChange={this.handleChange('agent_code')}
                            label="Mã nhân viên"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_name"
                            value={this.state.agent_name}
                            label="Họ và tên"
                            InputLabelProps={{ shrink: this.state.agent_name ? true : false }}
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_national_id"
                            value={this.state.agent_national_id}
                            label="CMND"
                            InputLabelProps={{ shrink: this.state.agent_national_id ? true : false }}
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_phone"
                            value={this.state.agent_phone}
                            label="Số điện thoại"
                            InputLabelProps={{ shrink: this.state.agent_phone ? true : false }}
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            select
                            margin="dense"
                            id="create_mc_product_code"
                            value={this.state.mc_product_code}
                            onChange={this.handleChange('mc_product_code')}
                            label="Sản phẩm (*)"
                            InputLabelProps={{ shrink: this.state.mc_product_code ? true : false }}
                        >
                            {this.state.products.map(pro => (
                                <MenuItem value={pro.code}>
                                    {pro.mc_code} - {pro.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_agent_mc_ref_code"
                            value={this.state.agent_mc_ref_code}
                            onChange={this.handleChange('agent_mc_ref_code')}
                            label="Mã nhân sự (đối tác cấp)"
                            type="text"
                        />

                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_ref_account"
                            value={this.state.ref_account}
                            onChange={this.handleChange('ref_account')}
                            label="Tài khoản đăng nhập (đối tác cấp)"
                            type="text"
                        />

                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_ref_account_code"
                            value={this.state.ref_account_code}
                            onChange={this.handleChange('ref_account_code')}
                            label="Code tài khoản đăng nhập (đối tác cấp)"
                            type="text"
                        />

                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_ref_password"
                            value={this.state.ref_password}
                            onChange={this.handleChange('ref_password')}
                            label="Mật khẩu (đối tác cấp)"
                            type="text"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisAgree} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateAgentProduct.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withToastManager(withStyles(styles)(CreateAgentProduct)));
