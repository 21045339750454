import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';
const agentURL = cs.BaseURL + '/agent/simple_detail';
const createAgentLocationURL = cs.BaseURL + '/agent/location/create';
const styles = theme => ({});

class CreateAgentLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            states: [],
            districts: [],
            wards: [],
            agent_code: '',
            adr_state_code: '',
            adr_district_code: '',
            adr_ward_code: '',
            is_default: false,
            invalid_code: false
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadStates();
    }

    handleDialogAgree = () => {
        if (!this.state.agent_code || !this.state.adr_state_code) {
            window.alert('Vui lòng nhập đầy đủ thông tin !');
            return;
        }

        let queryString = `${createAgentLocationURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                agent_code: this.state.agent_code,
                adr_state_code: this.state.adr_state_code,
                adr_district_code: this.state.adr_district_code,
                adr_ward_code: this.state.adr_ward_code,
                is_default: this.state.is_default ? 1 : 0
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Đã tạo phân quyền !', {
                        appearance: 'success',
                        autoDismiss: true,
pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/agent_location',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleDialogDisAgree = () => {
        this.props.history.push({
            pathname: '/agent_location',
            state: { reload: true }
        });
    };

    loadStates() {
        let queryString = `${statesURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        states: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        let queryString = `${districtsURL}?state_code=${this.state.adr_state_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_district: '', adr_district_code: null });
                    this.setState({
                        districts: data.data
                    });
                } else {
                    this.setState({
                        districts: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {});
    }

    loadWards() {
        let queryString = `${wardsURL}?district_code=${this.state.adr_district_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_ward: '', adr_ward_code: null });
                    this.setState({
                        wards: data.data
                    });
                } else {
                    this.setState({
                        wards: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {});
    }

    loadAgent() {
        let queryString = `${agentURL}?new=true&code=${this.state.agent_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data = data.data;

                    this.setState({
                        invalid_code: false,
                        agent_code: data.code,
                        agent_name: data.name,
                        agent_national_id: data.national_id,
                        agent_phone: data.phone
                    });
                } else {
                    this.setState({
                        invalid_code: true,
                        agent_name: '',
                        agent_national_id: '',
                        agent_phone: ''
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        if (name == 'is_default') this.setState({ is_default: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == 'adr_state_code') this.loadDistricts();
                    if (name == 'adr_district_code') this.loadWards();
                    if (name == 'agent_code') this.loadAgent();
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="create_agent_loc_form-dialog-title">Phân quyền địa bàn cho DSA</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_code"
                            error={this.state.invalid_code}
                            helperText={this.state.invalid_code ? 'DSA không tồn tại' : ' '}
                            required={true}
                            value={this.state.agent_code}
                            onChange={this.handleChange('agent_code')}
                            label="Mã nhân viên"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_name"
                            value={this.state.agent_name}
                            label="Họ và tên"
                            InputLabelProps={{ shrink: this.state.agent_name ? true : false }}
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_national_id"
                            value={this.state.agent_national_id}
                            label="CMND"
                            InputLabelProps={{ shrink: this.state.agent_national_id ? true : false }}
                        />
                        <TextField
                            fullWidth
                            disabled
                            id="agent_phone"
                            value={this.state.agent_phone}
                            label="Số điện thoại"
                            InputLabelProps={{ shrink: this.state.agent_phone ? true : false }}
                        />
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_agent_loc_adr_state">Tỉnh/thành phố (*)</InputLabel>
                            <Select
                                value={this.state.adr_state_code}
                                onChange={this.handleChange('adr_state_code')}
                                inputProps={{
                                    name: 'adr_state',
                                    id: 'create_agent_loc_adr_state'
                                }}
                                InputLabelProps={{ shrink: this.state.adr_state_code ? true : false }}
                            >
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>{pro.state_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>{' '}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_agent_loc_adr_district">Quận / huyện (*)</InputLabel>
                            <Select
                                value={this.state.adr_district_code}
                                onChange={this.handleChange('adr_district_code')}
                                inputProps={{
                                    name: 'adr_district',
                                    id: 'create_agent_loc_adr_district'
                                }}
                                InputLabelProps={{ shrink: this.state.adr_district_code ? true : false }}
                            >
                                {this.state.districts.map(pro => (
                                    <MenuItem value={pro.district_code}>{pro.district_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>{' '}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_agent_loc_adr_ward">Xã/Phường</InputLabel>
                            <Select
                                value={this.state.adr_ward_code}
                                onChange={this.handleChange('adr_ward_code')}
                                inputProps={{
                                    name: 'adr_ward',
                                    id: 'create_agent_loc_adr_ward'
                                }}
                            >
                                {this.state.wards.map(pro => (
                                    <MenuItem value={pro.ward_code}>{pro.ward_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div fullWidth className={classes.is_default_button}>
                            <Checkbox
                                checked={this.state.is_default}
                                value={this.state.is_default}
                                onChange={this.handleChange('is_default')}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            Địa bàn mặc đinh
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisAgree} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateAgentLocation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withToastManager(withStyles(styles)(CreateAgentLocation)));
