import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Legend
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';

const productsURL = cs.BaseURL + '/product/list';
const URL = cs.BaseURL + '/report/monthly_reject_summary';

function formatGridValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('MM/YYYY');
    if (value.created_qty == 0 || value.created_qty == '0') value.created_qty = '-';
    if (value.canceled_qty == 0 || value.canceled_qty == '0') value.canceled_qty = '-';
    if (value.agent_submitted_qty == 0 || value.agent_submitted_qty == '0')
        value.agent_submitted_qty = '-';
    if (value.op_approved_qty == 0 || value.op_approved_qty == '0') value.op_approved_qty = '-';
    if (value.op_rejected_qty == 0 || value.op_rejected_qty == '0') value.op_rejected_qty = '-';
    if (value.mc_approved_qty == 0 || value.mc_approved_qty == '0') value.mc_approved_qty = '-';
    if (value.mc_rejected_qty == 0 || value.mc_rejected_qty == '0') value.mc_rejected_qty = '-';
    return value;
}

function formatChartValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('MM/YYYY');
    return value;
}
class MonthlySummaryByReject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            merchants: [],
            products: [],
            start_months: [],
            end_months: [],

            gridData: [],
            gridColumns: [
                { name: 'report_date', title: 'Tháng' },
                { name: 'reject_desc', title: 'Lý do' },
                { name: 'region', title: 'Miền' },
                { name: 'sub_region', title: 'Vùng' },
                { name: 'reject_qty', title: 'Số lượng' }
            ],
            gridColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 150 },
                { columnName: 'reject_desc', align: 'left' },
                { columnName: 'region', align: 'center', width: 130 },
                { columnName: 'sub_region', align: 'center', width: 130 },
                { columnName: 'reject_qty', align: 'center', width: 150 }
            ],
            gridTotalCount: 0,
            gridCurrentPage: 0,

            pageSize: 15,
            loading: true,
            search_date_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .startOf('month')
                .subtract(12, 'month')
                .unix(),

            search_date_end: moment()
                .tz('Asia/Ho_Chi_Minh')
                .endOf('month')
                .unix()
        };

        this.changeGridCurrentPage = this.changeGridCurrentPage.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.loadMonths = this.loadMonths.bind(this);

        this.loadProducts();
        this.loadMonths();
    }
    componentDidMount() {
        this.loadMonths();
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    loadMonths() {
        var start_months = [];
        var end_months = [];

        // start_months.push({ value: null, name: ' ' });
        // end_months.push({ value: null, name: ' ' });

        for (var i = -12; i <= 0; i++) {
            var current = null;

            current = moment()
                .add(i, 'month')
                .startOf('month');

            start_months.push({
                value: current.unix(),
                display: current.tz('Asia/Ho_Chi_Minh').format('MM/YYYY')
            });

            current = moment()
                .add(i, 'month')
                .endOf('month');
            end_months.push({
                value: current.unix(),
                display: current.tz('Asia/Ho_Chi_Minh').format('MM/YYYY')
            });
        }

        this.setState({
            start_months: start_months,
            end_months: end_months
        });
    }

    loadProducts() {
        let queryString = `${productsURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var merchants = [];
                    merchants.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = merchants.some(el => el.code === p.mc_code);
                        if (found) return;
                        merchants.push({ code: p.mc_code, name: p.mc_code });
                    });

                    var products = [];
                    products.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = products.some(el => el.code === p.code);
                        if (found) return;
                        products.push(p);
                    });

                    this.setState({
                        products: products,
                        merchants: merchants
                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    changeGridCurrentPage(page) {
        this.setState(
            {
                loading: true,
                gridCurrentPage: page
            },
            () => {
                this.loadGridData();
            }
        );
    }

    queryGridString() {
        const {
            pageSize,
            gridCurrentPage,
            search_date_start,
            search_date_end,
            searchProduct,
            searchMerchant,
            searchRegion,
            searchSubRegion,
            searchRejectDesc
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${gridCurrentPage +
            1}&orderby=report_date&asc=false&group_field1=reject_type&group_field2=sub_region`;

        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchRegion) queryString = `${queryString}&region=${searchRegion}`;
        if (searchSubRegion) queryString = `${queryString}&sub_region=${searchSubRegion}`;
        if (searchRejectDesc) queryString = `${queryString}&reject_desc=${searchRejectDesc}`;

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment
                .unix(search_date_start)
                .startOf('month')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment
                .unix(search_date_end)
                .endOf('month')
                .unix()}`;

        return queryString;
    }
    loadGridData = conditions => {
        const queryString = this.queryGridString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        gridData: data.data,
                        gridTotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        gridData: [],
                        gridTotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() =>
                this.setState({
                    gridData: [],
                    loading: false
                })
            );
    };

    loadData = conditions => {
        this.loadGridData();
    };
    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            chartData,

            gridData,
            gridColumns,
            gridColumnConfig,
            gridCurrentPage,
            gridTotalCount,

            pageSize,
            loading
        } = this.state;

        return (
            <div>
                <form className={classes.search_container} noValidate autoComplete="off">
                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="productId">Từ tháng</InputLabel>
                        <Select
                            value={this.state.search_date_start}
                            onChange={this.handleChange('search_date_start')}
                            inputProps={{
                                name: 'date_start',
                                id: 'date_startId'
                            }}
                        >
                            {this.state.start_months.map(item => (
                                <MenuItem value={item.value}>
                                    {item.value && <div>{item.display}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="productId">Đến tháng</InputLabel>
                        <Select
                            value={this.state.search_date_end}
                            onChange={this.handleChange('search_date_end')}
                            inputProps={{
                                name: 'date_end',
                                id: 'date_endId'
                            }}
                        >
                            {this.state.end_months.map(item => (
                                <MenuItem value={item.value}>
                                    {item.value && <div>{item.display}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="search_region"
                        select
                        label="Miền"
                        className={classes.search_field}
                        value={this.state.searchRegion}
                        onChange={this.handleChange('searchRegion')}
                        margin="normal"
                        InputLabelProps={{ shrink: this.state.searchRegion ? true : false }}
                    >
                        <MenuItem value={null}></MenuItem>
                        <MenuItem value="MIỀN NAM">MIỀN NAM</MenuItem>
                        <MenuItem value="MIỀN BẮC">MIỀN BẮC</MenuItem>
                    </TextField>
                    <TextField
                        id="search_sub_region"
                        select
                        label="Vùng"
                        className={classes.search_field}
                        value={this.state.searchSubRegion}
                        onChange={this.handleChange('searchSubRegion')}
                        margin="normal"
                        InputLabelProps={{ shrink: this.state.searchSubRegion ? true : false }}
                    >
                        <MenuItem value={null}></MenuItem>
                        <MenuItem value="N1">N1</MenuItem>
                        <MenuItem value="N2">N2</MenuItem>
                        <MenuItem value="N3">N3</MenuItem>
                        <MenuItem value="N4">N4</MenuItem>
                        <MenuItem value="N5">N5</MenuItem>
                        <MenuItem value="N6">N6</MenuItem>
                        <MenuItem value="N7">N7</MenuItem>
                        <MenuItem value="N8">N8</MenuItem>
                        <MenuItem value="N9">N9</MenuItem>
                        <MenuItem value="N10">N10</MenuItem>
                        <MenuItem value="B1">B1</MenuItem>
                        <MenuItem value="B2">B2</MenuItem>
                        <MenuItem value="B3">B3</MenuItem>
                        <MenuItem value="B4">B4</MenuItem>
                        <MenuItem value="B5">B5</MenuItem>
                        <MenuItem value="B6">B6</MenuItem>
                        <MenuItem value="B7">B7</MenuItem>
                        <MenuItem value="B8">B8</MenuItem>
                        <MenuItem value="B9">B9</MenuItem>
                        <MenuItem value="B10">B10</MenuItem>
                    </TextField>

                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="merchantId">Đối tác</InputLabel>
                        <Select
                            value={this.state.searchMerchant}
                            onChange={this.handleChange('searchMerchant')}
                            inputProps={{
                                name: 'merchant',
                                id: 'merchantId'
                            }}
                        >
                            {this.state.merchants.map(item => (
                                <MenuItem value={item.code}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.search_product_field}>
                        <InputLabel htmlFor="productId">Sản phẩm</InputLabel>
                        <Select
                            value={this.state.searchProduct}
                            onChange={this.handleChange('searchProduct')}
                            inputProps={{
                                name: 'product',
                                id: 'productId'
                            }}
                        >
                            {this.state.products.map(pro => (
                                <MenuItem value={pro.code}>
                                    {pro.code && (
                                        <div>
                                            {pro.mc_code} - {pro.name}
                                        </div>
                                    )}
                                    {!pro.code && <div>{pro.name}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        id="reject_desc_id"
                        label="Lý do từ chối"
                        type="search"
                        className={classes.search_product_field}
                        value={this.state.searchRejectDesc}
                        onChange={this.handleChange('searchRejectDesc')}
                        margin="normal"
                    />
                </form>
                <Typography
                    variant="h5"
                    gutterBottom
                    component="h2"
                    style={{ marginTop: 30, marginLeft: 10 }}
                >
                    Theo Vùng Miền
                </Typography>
                <Paper className={classes.grid_Container}>
                    <Grid rows={gridData} columns={gridColumns} getRowId={getGridRowId}>
                        <PagingState
                            currentPage={gridCurrentPage}
                            onCurrentPageChange={this.changeGridCurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={gridTotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={gridColumnConfig}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>{' '}
            </div>
        );
    }
}

const getGridRowId = row => row.report_date + row.sub_region + row.reject_type;

MonthlySummaryByReject.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 150,
        minWidth: 120
    },
    search_product_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 400,
        minWidth: 300
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    },
    chart: {
        marginTop: 30
    }
});

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                textAlign: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => (
    <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withStyles(styles)(MonthlySummaryByReject);
