import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment-timezone';

import cs from '../../const.js';
import AgentLocationList from './AgentLocationList';
import AgentLocationStatistic from './AgentLocationStatistic';

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 2, minHeight: 350 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const isOPLeader = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_Leader;
};
const isOPManager = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_Manager;
};

class AgentLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabNum: 0
        };
    }

    componentDidMount() {}

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };
    render() {
        return (
            <div>
                {isOPManager() && (
                    <div>
                        <AppBar position="static">
                            <Tabs value={this.state.tabNum} onChange={this.handleTabChange}>
                                <Tab label="Phân quyền" />
                                <Tab label="Thống kê" />
                            </Tabs>
                        </AppBar>
                        <div style={{ minHeight: 400 }}>
                            {this.state.tabNum === 0 && (
                                <TabContainer>
                                    <AgentLocationList />
                                </TabContainer>
                            )}

                            {this.state.tabNum === 1 && (
                                <TabContainer>
                                    <AgentLocationStatistic />
                                </TabContainer>
                            )}
                        </div>{' '}
                    </div>
                )}

                {!isOPManager() && (
                    <div style={{ minHeight: 400 }}>
                        
                        <AgentLocationList />
                    </div>
                )}
            </div>
        );
    }
}

const styles = theme => ({});

export default withRouter(withStyles(styles)(AgentLocation));
