import React from 'react';
import { BrowserRouter as Router,withRouter, Route, Link } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from 'moment-timezone';

import cs from '../../const.js';
import DeleteUserPerm from './DeleteOPAllocation';
import CreateOPAllocation from './CreateOPAllocation';
import EditOPAllocation from './EditOPAllocation';
const URL = cs.BaseURL + '/op-allocation/list';

function formatValue(value) {
    if (value && value.adr_district) {
        value.adr_district = value.adr_district.replace('Thành phố', 'TP').trim();
        value.adr_district = value.adr_district.replace('Huyện', '').trim();
    }

    if (value && value.adr_state) {
        value.adr_state = value.adr_state.replace('Thành phố', 'TP').trim();
        value.adr_state = value.adr_state.replace('Tỉnh', '').trim();
    }

    if (value && value.role) {
        if (value.role == cs.Role_Admin) value.role = 'Quản trị hệ thống';
        if (value.role == cs.Role_OP_User) value.role = 'Vận hành - Nhân viên';
        if (value.role == cs.Role_OP_Leader) value.role = 'Vận hành - Trưởng phòng';
        if (value.role == cs.Role_OP_Manager) value.role = 'Vận hành - Quản lý';

        if (value.role == cs.Role_Merchant_User) value.role = 'Đối tác - Nhân viên';
        if (value.role == cs.Role_Merchant_Leader) value.role = 'Đối tác - Trưởng phòng';
        if (value.role == cs.Role_Merchant_Manager) value.role = 'Đối tác - Quản lý';

        if (value.role == cs.Role_HR_User) value.role = 'Nhân sự - Nhân viên';
        if (value.role == cs.Role_HR_Leader) value.role = 'Nhân sự - Trưởng phòng';
        if (value.role == cs.Role_HR_Manager) value.role = 'Nhân sự - Quản lý';
    }
    if (value && value.locked) value.locked = 'Locked';
    else value.locked = '';

    if (value && value.is_enabled) value.is_enabled = ' ';
    else value.is_enabled = 'Vô hiệu';

    return value;
}

class OPAllocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'user_name', title: 'Nhân viên' },
                { name: 'user_fullname', title: 'Họ và tên' },
                { name: 'mc_code', title: 'Đối tác' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                { name: 'allocation_weight', title: 'Trọng số' },
                { name: 'is_enabled', title: 'Hiệu lực' },
                { name: 'action', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'user_name', align: 'left', width: 140 },
                { columnName: 'user_fullname', align: 'left', width: 270 },
                { columnName: 'mc_code', align: 'center', width: 150 },
                { columnName: 'mc_product_name', align: 'left' },
                { columnName: 'allocation_weight', align: 'center', width: 150 },
                { columnName: 'is_enabled', align: 'center', width: 150 },
                { columnName: 'action', align: 'center', width: 120 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'user_name', direction: 'desc' }],
            searchStatus: ''
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,

            searchName,
            searchFullName,
            searchProduct,
            searchMerchant
        } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;
        if (searchName) queryString = `${queryString}&user_name=${searchName}`;
        if (searchFullName) queryString = `${queryString}&user_fullname=${searchFullName}`;

        if (searchProduct) queryString = `${queryString}&mc_product_name=${searchProduct}`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                if (name == 'searchType')
                    this.setState({ searchRole: null, currentPage: 0 }, () => {
                        this.loadData();
                    });
                else
                    this.setState({ currentPage: 0 }, () => {
                        this.loadData();
                    });
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.props.history.location.state.reload = false;
            this.loadData();
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New OP Allocation"
                    component={Link}
                    to={'/op_allocation/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Tạo mới</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>
                <ExpansionPanel>
                    <ExpansionPanelSummary className={classes.search_heading} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="user_name"
                                label="Nhân viên vận hành"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchName}
                                onChange={this.handleChange('searchName')}
                                margin="normal"
                            />

                            <TextField
                                id="user_fullname"
                                label="Họ và tên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchFullName}
                                onChange={this.handleChange('searchFullName')}
                                margin="normal"
                            />

                            <TextField
                                id="mc_name"
                                label="Đối tác"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchMerchant}
                                onChange={this.handleChange('searchMerchant')}
                                margin="normal"
                            />

                            <TextField
                                id="mc_product_name"
                                label="Sản phẩm"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchProduct}
                                onChange={this.handleChange('searchProduct')}
                                margin="normal"
                            />
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="800px"
                        />
                        <TableHeaderRow
                            showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
                <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                    <Route exact path="/op_allocation/create" component={CreateOPAllocation} />
                    <Route exact path="/op_allocation/edit/:id" component={EditOPAllocation} />
                    <Route exact path="/op_allocation/delete/:id" component={DeleteUserPerm} />
                </ToastProvider>
            </div>
        );
    }
}

const getRowId = row => row.id;
OPAllocationList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },

    cell_button: {
        width: 100,
        marginLeft: 2,
        marginRight: 2
    }
});

class ActionCell extends React.Component {
    isSysAdmin = () => {
        var username = this.props.row.name;
        return username == 'sysadmin';
    };

    render() {
        return (
            <Table.Cell
                style={{
                    padding: 2,
                    justifyContent: 'center'
                }}
            >
                <span>
                    {!this.isSysAdmin() && (
                        <Button
                            color="primary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/op_allocation/edit/' + this.props.row.id}
                        >
                            <Icon>edit</Icon>
                        </Button>
                    )}

                    {!this.isSysAdmin() && (
                        <Button
                            color="secondary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/op_allocation/delete/' + this.props.row.id}
                        >
                            <Icon>remove_circle</Icon>
                        </Button>
                    )}
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }
    if (column.name === 'user_name') {
        return (
            <Table.Cell
                {...props}
                style={{
                    margin: 1,
                    padding: 1,
                    paddingLeft: 20
                }}
            />
        );
    }

    return (
        <Table.Cell
            {...props}
            style={{
                margin: 1,
                padding: 1
            }}
        />
    );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                paddingLeft: 40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};
const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(OPAllocationList));
