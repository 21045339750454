import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "../../const.js";
import moment from "moment-timezone";
// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/agent/kpi/list';
const updateKPIURL = cs.BaseURL + "/agent/kpi/update";
const agentURL = cs.BaseURL + '/agent/list-agent';
const productURL = cs.BaseURL + '/agent/product';
const merchantURL = cs.BaseURL + '/agent/merchant';
const leaderURL = cs.BaseURL + '/agent/leader';

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
});

function formatValue(value) {
    // format display of time
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');

    if (value && value.ended_at)
        value.ended_at = moment
            .unix(value.ended_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    return value;
}

const isTelesaleOP = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Hub_Leader
        || userrole === cs.Role_Telesale_Hub_Manager
    );
}

const range = (start, stop, step) => Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + (i * step)
);


class UpdatePKI extends React.Component {


    constructor(props) {
        super(props);
        const currentYear = (new Date()).getFullYear();

        this.state = {
            id: this.props.match.params.id,
            year: '',
            month: '',
            agent_code: '',
            agent_name: '',
            agent_phone: '',
            team_name: '',
            mc_code: '',
            mc_product_code: '',
            mc_product_name: '',

            kpi_plan: '',

            products: [],
            agents: [],
            merchants: [],
            leaders: [],
            years: range(currentYear, currentYear - 50, -1),
            months: Array.from(new Array(12), (x, i) => i + 1)
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        //this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        //this.loadMerchants();
        this.loadMerchants();
        this.loadAgents();
        this.loadLeaders();
        this.loadData();
    }

    loadAgents() {
        let queryString = `${agentURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        agents: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadLeaders() {
        let queryString = `${leaderURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        leaders: data.data
                    });
                } else {
                    this.setState({
                        leaders: []
                    });

                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    leaders: []
                });
            });
    }

    loadMerchants() {
        let queryString = `${merchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        merchants: data.data
                    });
                } else {
                    this.setState({
                        merchants: []
                    });

                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchants: []
                });
            });
    }

    loadProducts(mc_id, mc_code) {
        let queryString = `${productURL}?mc_id=${mc_id}&mc_code=${mc_code}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ name: '', code: '' });
                    this.setState({
                        products: data.data
                    });
                } else {
                    this.setState({
                        products: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?id=${id}`;
        return queryString;
    }

    loadData() {
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-op-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = formatValue(data.data[0]);
                    this.setState({
                        year: tmp.year,
                        month: tmp.month,
                        agent_code: tmp.agent_code,
                        agent_name: tmp.agent_name,
                        agent_phone: tmp.agent_phone,
                        team_name: tmp.team_name,
                        mc_code: tmp.mc_code,
                        mc_product_code: tmp.mc_product_code,
                        mc_product_name: tmp.mc_product_name,
                        kpi_plan: tmp.kpi_plan,
                    }, () => {
                        let merchant = this.state.merchants.filter((el) => el.code === tmp.mc_code)
                        console.log(merchant)
                        if (merchant.length) {
                            this.loadProducts(merchant[0].id, merchant[0].code);

                        }                        // this.setState({
                        //     mc_product_code: tmp.mc_product_code,
                        //     mc_product_name: tmp.mc_product_name,

                        // });
                    });

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        // sessionStorage.clear();
                        // localStorage.clear();
                        // window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state)
        if (!this.state.year || !this.state.month
            || !this.state.agent_code || !this.state.agent_name
            || !this.state.agent_phone
            // || !this.state.mc_product_code
            // || !this.state.mc_product_name
            //|| !this.state.team_name
            || !this.state.kpi_plan
        ) {
            window.alert('Please input information !');
            return;
        }

        if (this.state.kpi_plan && isNaN(this.state.kpi_plan)) {
            window.alert('Incorrect amount !');
            return;
        }

        let queryString = `${updateKPIURL}`;
        let requestBody = {
            id: parseInt(this.state.id),
            year: this.state.year,
            month: this.state.month,
            agent_code: this.state.agent_code,
            agent_name: this.state.agent_name,
            agent_phone: this.state.agent_phone,
            mc_code: this.state.mc_code,
            mc_product_code: this.state.mc_product_code,
            mc_product_name: this.state.mc_product_name,
            team_name: this.state.team_name,
            kpi_plan: this.state.kpi_plan
        };

        console.log(requestBody);
        fetch(queryString, {
            method: "POST",

            body: JSON.stringify(requestBody),
            headers: {
                Authorization: localStorage.getItem(cs.System_Code + '-op-token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed.code) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("KPI is updated !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/kpi',
                    state: { reload: true }
                });
                // window.location.href = "/kpi";
                //window.location.reload();
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        // this.setState({
        //     [name]: event.target.value
        // });
        console.log(event.target.value, event.target.checked);
        if (name == "like_able" || name == "comment_able") this.setState({ [name]: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == "agent_code") {
                        let agent = this.state.agents.filter((el) => el.code === event.target.value)
                        console.log(agent);
                        // this.loadProducts(agent[0].id);
                        this.setState({
                            // mc_product_code: '',
                            // mc_product_name: '',
                            agent_name: agent[0].name,
                            agent_phone: agent[0].phone,
                            team_name: agent[0].team_name || ""
                        })
                    }
                    //let merchant = this.state.merchants.filter((el) => el.code === tmp.mc_code)
                    if (name == "mc_code") {
                        let merchant = this.state.merchants.filter((el) => el.code === event.target.value)
                        this.setState({
                            mc_product_code: '',
                            mc_product_name: '',
                        })
                        this.loadProducts(merchant[0].id, merchant[0].code);
                    }
                    if (name == "mc_product_code") {
                        let product = this.state.products.filter((el) => el.code === event.target.value)
                        this.setState({ mc_product_name: product[0].name })
                    }
                }
            );
    };


    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        const { classes } = this.props;
        const { merchant } = this.state;
        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth='sm'>
                    <DialogTitle id="edit_merchant_form-dialog-title">
                        Update KPI
                    </DialogTitle>
                    <DialogContent>
                        <FormControl disabled fullWidth>
                            <InputLabel htmlFor="merchantId">Agent </InputLabel>
                            <Select
                                value={this.state.agent_code}
                                onChange={this.handleChange('agent_code')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {/* <MenuItem value="{item}">e</MenuItem> */}
                                {this.state.agents.map(item => (
                                    <MenuItem value={item.code}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            disabled
                            fullWidth
                            //autoFocus
                            margin="dense"
                            id="title"
                            required={true}
                            value={this.state.agent_name}
                            onChange={this.handleChange('agent_name')}
                            label="Agent name "
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            //autoFocus
                            margin="dense"
                            id="agent_phone"
                            required={true}
                            value={this.state.agent_phone}
                            onChange={this.handleChange('agent_phone')}
                            label="Agent phone"
                            type='text'
                        />

                        <FormControl disabled fullWidth>
                            <InputLabel htmlFor="merchantId">Team name </InputLabel>
                            <Select
                                value={this.state.team_name}
                                onChange={this.handleChange('team_name')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {/* <MenuItem value="{item}">e</MenuItem> */}
                                {this.state.leaders.map(item => (
                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl disabled fullWidth>
                            <InputLabel htmlFor="merchantId">Đối tác</InputLabel>
                            <Select
                                value={this.state.mc_code}
                                onChange={this.handleChange('mc_code')}
                                inputProps={{
                                    name: 'merchant',
                                    id: 'merchantId'
                                }}
                            >
                                {this.state.merchants.map(item => (
                                    <MenuItem value={item.code}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl disabled fullWidth>
                            <InputLabel htmlFor="merchantId">Sản phẩm</InputLabel>
                            <Select
                                value={this.state.mc_product_code}
                                onChange={this.handleChange('mc_product_code')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {/* <MenuItem value="{item}">e</MenuItem> */}
                                {this.state.products.map(item => (
                                    <MenuItem value={item.code}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="priority"
                            required={true}
                            value={this.state.kpi_plan}
                            onChange={this.handleChange('kpi_plan')}
                            label="KPI Plan"
                            type='number'
                        //InputProps={{ inputProps: { min: 0, max: 10 } }}
                        />


                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >



                            <Grid item xs={6}>
                                {/* <div fullWidth className={classes.create_button}>
                                    Like
                                    <Switch
                                        checked={this.state.like_able}
                                        value={this.state.like_able}
                                        onChange={this.handleChange('like_able')}
                                        color="primary"
                                    />
                                    {this.state.like_able ? 'Có' : 'Không'}
                                </div> */}
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Month </InputLabel>
                                    <Select
                                        value={this.state.month}
                                        onChange={this.handleChange('month')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >
                                        {/* <MenuItem value="{item}">e</MenuItem> */}
                                        {this.state.months.map(item => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Year </InputLabel>
                                    <Select
                                        value={this.state.year}
                                        onChange={this.handleChange('year')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >
                                        {/* <MenuItem value="{item}">e</MenuItem> */}
                                        {this.state.years.map(item => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

UpdatePKI.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(UpdatePKI));
