import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';

import { ToastProvider } from 'react-toast-notifications';
// import CreateNews from './CreateNews.js';
// import EditNews from './UpdateNews.js';
// import DeleteNews from './DeleteNews.js';
// import NewsDetail from './detail/NewsDetail.js';
// import RequestEditNews from './RequestEditNews.js';
// import ApproveNews from './ApproveNews.js';
// import CancelNews from './CancelNews.js';
import { Menu, Switch } from '@material-ui/core';
import CreateKPI from './CreateKPI.js';

import UpdateKPI from './UpdateKPI.js';
import DeleteKPI from './DeleteKPI.js';
import AllocateKPI from './AllocateKPI.js';
//import { Link, Route, withRouter } from 'react-router-dom';

const URL = cs.BaseURL + '/agent/kpi/list';

const activeStatus = ["Chưa phê duyệt", "Phê duyệt", '', "Yêu cầu sửa lại", "Tắt"];

function formatValue(value) {
    // format display of time
    if (value && value.lead_created_at_unix)
        value.lead_created_at_unix = moment
            .unix(value.lead_created_at_unix)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY HH:mm');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    if (value && value.active !== null) {
        value.active = activeStatus[value.active];
    }
    return value;
}
const isTelesaleUser = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (userrole === cs.Role_Telesale_Hub_User
        || userrole === cs.Role_Telesale_Hub_Leader
        || userrole === cs.Role_Telesale_Hub_Manager
        || userrole === cs.Role_Telesale_Merchant_User
        || userrole === cs.Role_Telesale_Merchant_Leader
        || userrole === cs.Role_Telesale_Merchant_Manager);
}

const isTelesaleOP = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Hub_Leader
        || userrole === cs.Role_Telesale_Hub_Manager
    );
}

const isTelesale_Merchant_Not_User = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Merchant_Leader || userrole === cs.Role_Telesale_Merchant_Manager
    );
}

const isTelesale_Merchant_Manager = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_Telesale_Merchant_Manager
    );
}

const isOPNotUser = () => {
    var user = localStorage.getItem(cs.System_Code + '-op-user');
    var userrole = JSON.parse(String(user)).role;
    return (
        userrole === cs.Role_OP_Leader
        || userrole === cs.Role_OP_Manager
    );
}

class OPKPIList extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-op-user');
        if (user) username = JSON.parse(String(user)).name;



        this.state = {
            columns: [
                { name: 'year', title: 'Năm' },
                { name: 'month', title: 'Tháng' },
                { name: 'agent_name', title: 'Agent' },
                { name: 'agent_code', title: 'Code' },
                { name: 'agent_phone', title: 'SĐT' },
                { name: 'team_name', title: 'Team name' },

                { name: 'mc_product_code', title: 'Mã sản phẩm' },
                { name: 'mc_product_name', title: 'Sản phẩm' },

                { name: 'kpi_plan', title: 'KPI được giao' },
                { name: 'kpi_actual', title: 'KPI thực tế' },
                { name: 'action', title: 'Thao tác' },

            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'month', align: 'center', width: 50 },
                { columnName: 'year', align: 'center', width: 100 },

                { columnName: 'team_name', align: 'center', width: 200 },
                { columnName: 'agent_name', align: 'center', width: 200 },
                { columnName: 'agent_code', align: 'center' },
                { columnName: 'agent_phone', align: 'center' },
                { columnName: 'mc_product_name', align: 'center', width: 150 },
                { columnName: 'mc_product_code', align: 'center', width: 150 },
                { columnName: 'kpi_plan', align: 'right' },
                { columnName: 'kpi_actual', align: 'right' },

                { columnName: 'action', align: 'center', width: 150 },
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],
            search_year: '',
            search_month: '',
            search_agent_name: '',
            search_agent_phone: '',
            search_agent_code: '',

            search_mc_product_code: '',
            search_mc_product_name: '',

            search_team_name: '',


        };

        if (!isOPNotUser()) {
            window.location.href = "/";
            return;
        }

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);

    }
    componentDidMount() {

        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }
    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }
    // changeSorting(sorting) {
    //     this.setState(
    //         {
    //             loading: true,
    //             sorting: sorting
    //         },
    //         () => {
    //             this.loadData();
    //         }
    //     );
    // }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,

            search_year,
            search_month,
            search_agent_name,
            search_agent_phone,
            search_agent_code,

            search_mc_product_code,
            search_mc_product_name,

            search_team_name,
        } = this.state;


        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }

        // if (searchAdrState && searchAdrState !=='') queryString = `${queryString}&current_province=${searchAdrState}`;
        // if (search_submitted_start)
        //     queryString = `${queryString}&search_submitted_start=${moment(search_submitted_start)
        //         .startOf('day')
        //         .unix()}`;
        // if (search_submitted_end)
        //     queryString = `${queryString}&search_submitted_end=${moment(search_submitted_end)
        //         .endOf('day')
        //         .unix()}`;
        if (search_agent_name && search_agent_name !== '') queryString = `${queryString}&agent_name=${search_agent_name}`;
        if (search_agent_phone && search_agent_phone !== '') queryString = `${queryString}&agent_phone=${search_agent_phone}`;
        if (search_agent_code && search_agent_code !== '') queryString = `${queryString}&agent_code=${search_agent_code}`;

        if (search_mc_product_code && search_mc_product_code !== '') queryString = `${queryString}&mc_product_code=${search_mc_product_code}`;
        if (search_mc_product_name && search_mc_product_name !== '') queryString = `${queryString}&mc_product_name=${search_mc_product_name}`;

        if (search_team_name && search_team_name !== '') queryString = `${queryString}&team_name=${search_team_name}`;

        if (search_month && search_month !== '') queryString = `${queryString}&month=${search_month}`;
        if (search_year && search_year !== '') queryString = `${queryString}&year=${search_year}`;

        // if (Number.isInteger(search_active) && search_active >= 0) queryString = `${queryString}&active=${search_active}`;

        // queryString = `${queryString}&deleted=${search_deleted ? 1 : 0}`
        return queryString;
    }


    loadData = conditions => {
        const queryString = this.queryString();


        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-op-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });

                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });

                    // prevent unauthorized access
                    if (data && (data.code === cs.erAuthenticationFailed.code || data.code === cs.erAccountIsLocked.code)) {

                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {

                this.setState({ loading: false });
            });
    };

    handleChange = name => event => {
        if (name == 'search_deleted') {
            this.setState(
                {
                    [name]: event.target.checked
                },
                () => {

                    this.setState({ currentPage: 0 });
                    this.loadData();
                }
            );
        } else {
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {

                    this.setState({ currentPage: 0 });
                    this.loadData();
                }
            );
        }

    };

    render() {
        const { classes } = this.props;

        // get value from state
        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">

                            <TextField
                                id="agent_name"
                                label="Agent"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_agent_name}
                                onChange={this.handleChange('search_agent_name')}
                                margin="normal"
                            />

                            <TextField
                                id="search_agent_phone"
                                label="SĐT"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_agent_phone}
                                onChange={this.handleChange('search_agent_phone')}
                                margin="normal"
                            />

                            <TextField
                                id="search_mc_product_code"
                                label="Code"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_agent_code}
                                onChange={this.handleChange('search_agent_code')}
                                margin="normal"
                            />

                            <TextField
                                id="search_mc_product_code"
                                label="Sản phẩm"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_mc_product_name}
                                onChange={this.handleChange('search_mc_product_name')}
                                margin="normal"
                            />

                            <TextField
                                id="search_mc_product_code"
                                label="Mã sản phẩm"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_mc_product_code}
                                onChange={this.handleChange('search_mc_product_code')}
                                margin="normal"
                            />



                            <TextField
                                id="month"
                                label="Tháng"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_month}
                                onChange={this.handleChange('search_month')}
                                margin="normal"
                            />

                            <TextField
                                id="year"
                                label="Năm"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_year}
                                onChange={this.handleChange('search_year')}
                                margin="normal"
                            />

                            {/* <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="search_active">Trạng thái</InputLabel>
                                <Select
                                    value={this.state.search_year}
                                    onChange={this.handleChange('search_year')}
                                    inputProps={{
                                        name: 'date_start',
                                        id: 'date_startId'
                                    }}
                                >
                                    <MenuItem value="">
                                        <div></div>
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        <div>{activeStatus[0]}</div>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <div>{activeStatus[1]}</div>
                                    </MenuItem>

                                    <MenuItem value={3}>
                                        <div>{activeStatus[3]}</div>
                                    </MenuItem>

                                    <MenuItem value={4}>
                                        <div>{activeStatus[4]}</div>
                                    </MenuItem>
                                </Select>
                            </FormControl> */}

                            {isTelesale_Merchant_Manager() &&
                                <TextField
                                    id="team_name"
                                    label="Team name"
                                    type="search"
                                    className={classes.search_field}
                                    value={this.state.search_team_name}
                                    onChange={this.handleChange('search_team_name')}
                                    margin="normal"
                                />
                            }
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create KPI"
                    component={Link}
                    to={'/kpi/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Đặt KPI</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Refresh</span>
                </Button>

                {/* <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    component={Link}
                    to={'/kpi/allocate'}

                >
                    <Icon>format_list_bulleted</Icon>
                    <span style={{ marginLeft: 10 }}> Đặt KPI chung</span>
                </Button> */}
                {/* 
                <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/kpi/allocate'}
                    >
                        Phân quyền
                    </Button> */}

                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            // showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>

                <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                    <Route exact path="/kpi/create" component={CreateKPI} />
                    <Route exact path="/kpi/update/:id" component={UpdateKPI} />
                    <Route exact path="/kpi/delete/:id" component={DeleteKPI} />
                    {/* <Route exact path="/kpi/allocate" component={AllocateKPI} /> */}
                    {/* <Route exact path="/news/edit/:id" component={RequestEditNews} />
                    <Route exact path="/news/approve/:id" component={ApproveNews} />
                    <Route exact path="/news/cancel/:id" component={CancelNews} /> */}

                </ToastProvider>
            </div>
        );
    }
}

const getRowId = row => row.id;
OPKPIList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        backgroundColor: green
    },
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell style={{
                padding: 1,
                justifyContent: 'center',
                fontSize: '12px'
            }}>
                <span>



                    <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/kpi/update/' + this.props.row.id}
                    >
                        <Icon>edit</Icon>
                    </Button>



                    <Button
                        color="secondary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/kpi/delete/' + this.props.row.id}
                    >
                        <Icon>delete_outline</Icon>
                    </Button>

                    {/* <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/kpi/allocate/' + this.props.row.id+'/'+this.props.row.agent_phone}
                    >
                        Phân quyền
                    </Button> */}
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2,
                color: '#81557a',
                fontSize: '12px'
            }}
        />
    );

    // var t1 = moment.unix(props.tableRow.row.submitted_at);
    // var t2 = moment();

    // var diff = t2.diff(t1, 'minutes');

    // if (diff <= 30)
    //     return (
    //         <Table.Cell
    //             {...props}
    //             style={{
    //                 padding: 2,
    //                 color: '#81557a',
    //                 fontSize: '12px'
    //             }}
    //         />
    //     );
    // else
    //     return (
    //         <Table.Cell
    //             {...props}
    //             style={{
    //                 padding: 2,
    //                 color: '#d34c3e',
    //                 fontSize: '12px'
    //             }}
    //         />
    //     );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(OPKPIList));
